import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { resetCreateKnowIpsStatus, resetUpdateKnownIpsStatus } from "../../redux/action/paymentIntentAction";
import { createKnowIps, updateKnownIps } from "../../redux/services/paymentIntentServices";
import KnownIpsInformation from "./KnownIpsInformation";

const CreateAndEditMerchant = ({
  token,
  createKnowIpsStatus,
  createKnowIps,
  resetCreateKnowIpsStatus,
  updateKnownIps,
  updateKnownIpsLoading,
  updateKnownIpsStatus,
  resetUpdateKnownIpsStatus,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const editMode = state?.editMode;
  const knowIpId = state?.knowIpId;

  const validationSchema = Yup.object().shape({
    ip: !editMode ? Yup.string().required("Ip is required") : Yup.string(),
    blocked: Yup.string().required("Block is required"),
    owner_name: Yup.string().required("Owner Name is required"),
    location: Yup.string().required("Location is required"),
    user_type: Yup.string().required("User Type is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const payload = {
      ...data,
    };

    if (editMode) {
      updateKnownIps(payload, knowIpId.known_ip_id, token);
    } else {
      createKnowIps(payload, token);
    }
  };

  useEffect(() => {
    if (createKnowIpsStatus === 200) {
      resetCreateKnowIpsStatus();
    }
  }, [createKnowIpsStatus, resetCreateKnowIpsStatus]);

  useEffect(() => {
    if (editMode) {
      setValue("blocked", knowIpId?.blocked);
      setValue("user_type", knowIpId?.user_type);
      setValue("location", knowIpId.location);
      setValue("owner_name", knowIpId?.owner_name);
    }
  }, [editMode, knowIpId, setValue]);

  useEffect(() => {
    if ((editMode && updateKnownIpsStatus === 200) || createKnowIpsStatus === 200) {
      navigate("/know-ips");
    }
    return () => {
      resetCreateKnowIpsStatus();
      resetUpdateKnownIpsStatus();
    };
  }, [
    updateKnownIpsStatus,
    createKnowIpsStatus,
    editMode,
    navigate,
    resetUpdateKnownIpsStatus,
    resetCreateKnowIpsStatus,
  ]);

  return (
    <div className="mt-10">
      <Container className="flex justify-center">
        <Box component="form" className="items-center shadow-2xl w-[50%] p-10 m-6">
          <p style={{ textAlign: "center", paddingTop: "40px", fontSize: "25px" }}>Known IP</p>
          <div className="flex flex-col gap-4 m-5">
            <KnownIpsInformation control={control} errors={errors} handleSubmit={handleSubmit} onSubmit={onSubmit} />
          </div>
        </Box>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    createKnowIpsStatus: state.paymentIntent.createKnowIpsStatus,
    createKnowIpsLoading: state.paymentIntent.createKnowIpsLoading,
    updateKnownIpsLoading: state.paymentIntent.updateKnownIpsLoading,
    updateKnownIpsStatus: state.paymentIntent.updateKnownIpsStatus,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    createKnowIps: (data, token) => {
      dispatch(createKnowIps(data, token));
    },
    resetCreateKnowIpsStatus: () => {
      dispatch(resetCreateKnowIpsStatus());
    },
    updateKnownIps: (data, id, token) => {
      dispatch(updateKnownIps(data, id, token));
    },
    resetUpdateKnownIpsStatus: () => {
      dispatch(resetUpdateKnownIpsStatus());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(CreateAndEditMerchant);
