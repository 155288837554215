import { Box, Button } from "@mui/material";
import { InputController } from "../ReusableComponents/InputBox";
import { TimePicker } from "../ReusableComponents/TimePicker";
import { DateController } from "../ReusableComponents/DateComponent";
import { useNavigate } from "react-router-dom";
import { NonRequiredInput } from "../ReusableComponents/NonRequiredInput";

const MerchantProfile = ({ control, errors, handleSubmit, onSubmit }) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/manage/merchant");
  };
  return (
    <div className="flex flex-col gap-4 justify-center">
      <InputController
        control={control}
        errors={errors.name}
        name="name"
        label={"Name"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <InputController
        control={control}
        errors={errors.logo_path}
        name="logo_path"
        label={"Logo"}
        width={"w-full"}
        size="small"
      />
      <TimePicker
        control={control}
        errors={errors.open_time}
        name="open_time"
        label={"Open Time"}
        type={"time"}
        width={"w-full"}
        size="small"
      />
      <TimePicker
        control={control}
        errors={errors.close_time}
        name="close_time"
        label={"Close Time"}
        type={"time"}
        width={"w-full"}
        size="small"
      />
      <DateController
        control={control}
        errors={errors.start_date}
        name="start_date"
        label={"Start Date"}
        type={"date"}
        width={"w-full"}
        size="small"
      />
      <DateController
        control={control}
        errors={errors.termination_date}
        name="termination_date"
        label={"Termination Date"}
        type={"date"}
        width={"w-full"}
        size="small"
      />
      <NonRequiredInput
        control={control}
        errors={errors.wait_time}
        name="wait_time"
        label={"Wait Time"}
        type={"number"}
        width={"w-full"}
        size="small"
      />

      <div className="flex items-center justify-center">
        <Box className="flex flex-row justify-center mx-auto gap-2 w-[180px] mt-3">
          <Button
            onClick={handleCancel}
            variant="contained"
            className="mx-auto w-[200px] text-lg text-white mt-4 normal-case"
          >
            <span>Cancel</span>
          </Button>
          <Button
            type="button"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            className="mx-auto w-[200px] text-lg text-white mt-4 normal-case"
          >
            <span>Submit</span>
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default MerchantProfile;
