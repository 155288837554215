import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPaymentIntent } from "../../redux/services/paymentIntentServices";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const PaymentIntent = ({ getPaymentIntent, getPaymentIntentResponse, token }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      getPaymentIntent(token);
    }
  }, [token, getPaymentIntent]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [statusFilter, setStatusFilter] = useState(["all", "initialized", "paid"]);
  const [customerFilter, setCustomerFilter] = useState("");
  const [orderByPayment, setOrderByPayment] = useState("id");
  const [order, setOrder] = useState("desc");

  const handleStatusFilterChange = (selectedStatus) => {
    setStatusFilter(selectedStatus);
  };

  const handleCustomerFilterChange = (customer) => {
    setCustomerFilter(customer);
  };

  const handleSort = (columnId) => {
    const isAsc = orderByPayment === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderByPayment(columnId);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefClick = (ref) => {
    console.log(ref);
    navigate("/request-logs/ref", { state: { ref: ref } });
  };
  const filteredPayments = getPaymentIntentResponse?.filter((payment) => {
    const statusCondition = statusFilter.includes("all") || statusFilter.includes(payment.status);
    const customerCondition = !customerFilter || payment.customer.toLowerCase().includes(customerFilter.toLowerCase());

    return statusCondition && customerCondition;
  });

  const getFilteredValues = () => {
    return filteredPayments
      .map((payment) => ({
        ...payment,
        amount: parseFloat(payment.amount),
        application_fee_amount: parseFloat(payment.application_fee_amount),
        amount_total: parseFloat(payment.amount_total),
        order_number: parseFloat(payment.order_number),
      }))
      .sort((a, b) => {
        const multiplier = order === "desc" ? 1 : -1;
        return (a[orderByPayment] < b[orderByPayment] ? 1 : -1) * multiplier;
      })
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
  return (
    <>
      <div className="mt-10 flex justify-center font-semibold text-2xl">Payment Intents</div>
      <div className="flex justify-end gap-3 mt-8">
        <div className="flex w-[30%] gap-5">
          <label className="font-semibold">Filter By Status: </label>
          <select
            className="flex justify-end border border-black w-[40%] h-[90%] rounded-md text-center cursor-pointer"
            value={statusFilter}
            onChange={(e) => handleStatusFilterChange([e.target.value])}
          >
            <option value="all" className="cursor-pointer">
              All
            </option>
            <option value="initialized" className="cursor-pointer">
              Initialized
            </option>
            <option value="paid" className="cursor-pointer">
              Paid
            </option>
          </select>
        </div>

        <div className="flex gap-3">
          <label className="font-semibold">Filter By Customer: </label>
          <input
            type="text"
            aria-label="Filter By Customer"
            className="border border-black rounded-md w-[24%] text-center"
            value={customerFilter}
            onChange={(e) => handleCustomerFilterChange(e.target.value)}
          />
        </div>
      </div>

      <TableContainer component={Card} className="mt-6 w-[100%]">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="font-bold">
                <TableSortLabel
                  active={orderByPayment === "id"}
                  direction={orderByPayment === "id" ? order : "desc"}
                  onClick={() => handleSort("id")}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell className="font-bold">
                <TableSortLabel
                  active={orderByPayment === "amount"}
                  direction={orderByPayment === "amount" ? order : "desc"}
                  onClick={() => handleSort("amount")}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell className="font-bold">
                <TableSortLabel
                  active={orderByPayment === "application_fee_amount"}
                  direction={orderByPayment === "application_fee_amount" ? order : "desc"}
                  onClick={() => handleSort("application_fee_amount")}
                >
                  Application Fee
                </TableSortLabel>
              </TableCell>
              <TableCell className="font-bold">
                <TableSortLabel
                  active={orderByPayment === "amount_total"}
                  direction={orderByPayment === "amount_total" ? order : "desc"}
                  onClick={() => handleSort("amount_total")}
                >
                  Total Amount
                </TableSortLabel>
              </TableCell>
              <TableCell className="font-bold">
                <TableSortLabel
                  active={orderByPayment === "customer"}
                  direction={orderByPayment === "customer" ? order : "desc"}
                  onClick={() => handleSort("customer")}
                >
                  Customer
                </TableSortLabel>
              </TableCell>
              <TableCell className="font-bold">
                <TableSortLabel
                  active={orderByPayment === "status"}
                  direction={orderByPayment === "status" ? order : "desc"}
                  onClick={() => handleSort("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell className="font-bold">Order Number</TableCell>
              <TableCell className="font-bold">
                <TableSortLabel
                  active={orderByPayment === "created"}
                  direction={orderByPayment === "created" ? order : "desc"}
                  onClick={() => handleSort("created")}
                >
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell className="font-bold">Ref</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getFilteredValues().map((payment) => (
              <TableRow key={payment.id}>
                <TableCell>{payment.id}</TableCell>
                <TableCell>${payment.amount.toFixed(2)}</TableCell>
                <TableCell>{payment.application_fee_amount.toFixed(2)}</TableCell>
                <TableCell>${payment.amount_total.toFixed(2)}</TableCell>
                <TableCell>{payment.customer}</TableCell>
                <TableCell>{payment.status}</TableCell>
                <TableCell>{payment.order_number}</TableCell>
                <TableCell>{moment(payment.created).format("MM/DD/YYYY HH:mm:ss")}</TableCell>
                <TableCell
                  className="text-blue-500 hover:text-blue-600 hover:cursor-pointer hover:underline"
                  onClick={() => handleRefClick(payment.ref)}
                >
                  {payment.ref}
                </TableCell>
              </TableRow>
            ))}
            {getFilteredValues()?.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell align="center" colSpan={9}>
                  No items to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 25, 50]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={getPaymentIntentResponse?.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    getPaymentIntentStatus: state.paymentIntent.getPaymentIntentStatus,
    getPaymentIntentResponse: state.paymentIntent.getPaymentIntentResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentIntent: (token) => {
      dispatch(getPaymentIntent(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentIntent);
