import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import {
  createMerchantProfile,
  getMerchantProfile,
  updateMerchantProfile,
} from "../../redux/services/merchantServices";
import MerchantProfile from "./MerchantProfile";
import { resetCreateMerchantProfileStatus, resetUpdateMerchantProfileStatus } from "../../redux/action/merchantAction";

const CreateAndEditMerchantProfile = ({
  token,
  createMerchantProfile,
  createMerchantProfileStatus,
  getMerchantProfile,
  updateMerchantProfile,
  updateMerchantProfileStatus,
  resetUpdateMerchantProfileStatus,
  resetCreateMerchantProfileStatus,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const editMode = state?.editMode;
  const merchantData = state?.merchantData;

  const validationSchema = Yup.object().shape({
    logo_path: Yup.string().required("Logo path is required"),
    name: Yup.string().required("Name is required"),
    open_time: Yup.string().nullable(),
    close_time: Yup.string().nullable(),
    start_date: Yup.string().nullable(),
    termination_date: Yup.string().nullable(),
    wait_time: Yup.number().nullable(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    data = {
      ...data,
    };

    if (editMode) {
      if (merchantData.merchant_profile_id) {
        updateMerchantProfile(data, merchantData.merchant_profile_id, token);
      }
    } else {
      createMerchantProfile(data, token);
    }
  };

  useEffect(() => {
    if (editMode) {
      setValue("logo_path", merchantData?.logo_path);
      setValue("name", merchantData?.name);
      setValue("open_time", merchantData?.open_time);
      setValue("close_time", merchantData?.close_time);
      setValue("start_date", merchantData?.start_date);
      setValue("termination_date", merchantData?.termination_date);
      setValue("wait_time", merchantData?.wait_time_in_minutes);
    }
  }, [editMode, merchantData, setValue]);

  useEffect(() => {
    if ((editMode && updateMerchantProfileStatus === 200) || createMerchantProfileStatus === 200) {
      navigate("/manage/merchant");
    }
    return () => {
      resetCreateMerchantProfileStatus();
      resetUpdateMerchantProfileStatus();
    };
  }, [
    updateMerchantProfileStatus,
    createMerchantProfileStatus,
    editMode,
    navigate,
    resetUpdateMerchantProfileStatus,
    resetCreateMerchantProfileStatus,
  ]);
  return (
    <div className="mt-10">
      <Container className="flex justify-center">
        <Box component="form" className="items-center shadow-2xl w-[50%] p-10 m-6">
          <p
            style={{
              textAlign: "center",
              paddingTop: "40px",
              fontSize: "25px",
            }}
          >
            {editMode ? "Update Merchant Profile" : "Create Merchant Profile"}
          </p>
          <div className="flex flex-col gap-4 m-5">
            <MerchantProfile control={control} errors={errors} handleSubmit={handleSubmit} onSubmit={onSubmit} />
          </div>
        </Box>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    createMerchantProfileStatus: state.merchant.createMerchantProfileStatus,
    createMerchantProfileLoading: state.merchant.createMerchantProfileLoading,
    getMerchantProfile: state.merchant.getMerchantProfile,
    updateMerchantProfileStatus: state.merchant.updateMerchantProfileStatus,
    updateMerchantProfile: state.merchant.updateMerchantProfile,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    getMerchantProfile: () => {
      dispatch(getMerchantProfile());
    },
    createMerchantProfile: (data, token) => {
      dispatch(createMerchantProfile(data, token));
    },
    updateMerchantProfile: (data, id, token) => {
      dispatch(updateMerchantProfile(data, id, token));
    },
    resetUpdateMerchantProfileStatus: () => {
      dispatch(resetUpdateMerchantProfileStatus());
    },
    resetCreateMerchantProfileStatus: () => {
      dispatch(resetCreateMerchantProfileStatus());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(CreateAndEditMerchantProfile);
