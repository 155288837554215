import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Notifications from "./Notifications";
import ManageMerchant from "./Manage/ManageMerchant";
import ResponsiveAppBar from "./ResponsiveAppBar";
import ManageKnowIps from "./ManageIps/ManageKnowIps"
import PaymentIntent from "./Payment/PaymentIntent";
import CreateAndEditMerchantProfile from "./Manage/CreateAndEditMerchantProfile";
import CreateAndEditMerchantAuthentication from "./Manage/CreateAndEditMerchantAuthentication";
import RequestLogs from "./RequestLog/RequestLog"
import RefRequestLogs from "./RequestLog/RefRequestLogs"
import ForwardForRequestLogs from "./RequestLog/ForwardForRequestLogs"
import CreateAndEditKnownIps from "./ManageIps/CreateAndEditKnownIps";

export const MainContainer = () => {
  return (
    <Routes>
      <Route path="/" element={ <><ResponsiveAppBar /> <Home /></> } />
      <Route path="/manage/merchant" element={ <><ResponsiveAppBar /> <ManageMerchant /></> } />
      <Route path="/manage/merchant-profiles" element={ <><ResponsiveAppBar /> <CreateAndEditMerchantProfile /></> } />
      <Route path="/create-merchant" element={ <><ResponsiveAppBar /> <CreateAndEditMerchantAuthentication /></> } />
      <Route path="/payment-intent" element={ <><ResponsiveAppBar /> <PaymentIntent /></> } />
      <Route path="/notification" element={ <><ResponsiveAppBar /> <Notifications /></> } />
      <Route path="/know-ips" element={<><ResponsiveAppBar/> <ManageKnowIps /></>} />
      <Route path="/create-know-ips" element={<><ResponsiveAppBar/> <CreateAndEditKnownIps /></>} />
      <Route path="/request-logs" element={<><ResponsiveAppBar/> <RequestLogs/></>} />
      <Route path="/request-logs/ref" element={<><ResponsiveAppBar/> <RefRequestLogs/></>} />
      <Route path="/request-logs/forward-for" element={<><ResponsiveAppBar/> <ForwardForRequestLogs/></>} />
    </Routes>
  );
};
