import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  resetCreateMerchantAuthenticationStatus,
  resetUpdateMerchantAuthenticationStatus,
} from "../../redux/action/merchantAction";
import { createMerchantAuthentication, updateMerchantAuthentication } from "../../redux/services/merchantServices";
import MerchantInformation from "./MerchantInformation";
const CreateAndEditMerchant = ({
  token,
  createMerchantAuthenticationLoading,
  createMerchantAuthenticationStatus,
  createMerchantAuthentication,
  resetCreateMerchantAuthenticationStatus,
  getMerchantAuthenticationResponse,
  updateMerchantAuthentication,
  updateMerchantAuthenticationLoading,
  updateMerchantAuthenticationStatus,
  resetUpdateMerchantAuthenticationStatus,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const editMode = state?.editMode;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    clientPublicKey: Yup.string().required("Key is required"),
    merchantId: Yup.string().required("MerchantId is required"),
    privateKey: Yup.string().nullable(),
    provider: Yup.string().required("Provider is required"),
    processingFeeFlat: Yup.string().nullable(),
    processingFeePercentage: Yup.string().nullable(),
    convenienceFeeFlat: Yup.string().nullable(),
    convenienceFeeFlatMax: Yup.string().nullable(),
    convenienceFeeFlatMin: Yup.string().nullable(),
    convenienceFeePercentage: Yup.string().nullable(),
    convenienceFeeType: Yup.string().required("Convenience Fee Type Min is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    const payload = {
      ...data,
      merchant_id: data.merchantId,
      client_public_key: data.clientPublicKey,
      private_key: data.privateKey,
      processing_fee_flat: data.processingFeeFlat,
      processing_fee_percentage: data.processingFeePercentage,
      convenience_fee_flat: data.convenienceFeeFlat,
      convenience_fee_flat_max: data.convenienceFeeFlatMax,
      convenience_fee_flat_min: data.convenienceFeeFlatMin,
      convenience_fee_percentage: data.convenienceFeePercentage,
      convenience_fee_type: data.convenienceFeeType,
    };
    delete payload.merchantId;
    delete payload.privateKey;
    delete payload.clientPublicKey;
    delete payload.processingFeeFlat;
    delete payload.processingFeePercentage;
    delete payload.convenienceFeeFlat;
    delete payload.convenienceFeeFlatMax;
    delete payload.convenienceFeeFlatMin;
    delete payload.convenienceFeePercentage;
    delete payload.convenienceFeeType;
    if (editMode) {
      if (getMerchantAuthenticationResponse.merchant_authentication_id) {
        updateMerchantAuthentication(payload, getMerchantAuthenticationResponse.merchant_authentication_id, token);
      }
    } else {
      createMerchantAuthentication(payload, token);
    }
  };
  useEffect(() => {
    setValue("processingFeeFlat", "0.50");
    setValue("processingFeePercentage", "3.5");
    setValue("convenienceFeeFlat", "1");
    setValue("convenienceFeeFlatMax", "1");
    setValue("convenienceFeeFlatMin", "1");
    setValue("convenienceFeePercentage", "0");
    setValue("convenienceFeeType", "per_order");
  }, [editMode, setValue]);

  useEffect(() => {
    if (createMerchantAuthenticationStatus === 200) {
      resetCreateMerchantAuthenticationStatus();
    }
  }, [createMerchantAuthenticationStatus, resetCreateMerchantAuthenticationStatus]);

  useEffect(() => {
    if (editMode) {
      setValue("name", getMerchantAuthenticationResponse.name);
      setValue("clientPublicKey", getMerchantAuthenticationResponse.client_public_key);
      setValue("merchantId", getMerchantAuthenticationResponse.merchant_authentication_id);
      setValue("privateKey", getMerchantAuthenticationResponse.private_key); //TODO
      setValue("provider", getMerchantAuthenticationResponse.provider);
      setValue("processingFeeFlat", getMerchantAuthenticationResponse.processing_fee_flat);
      setValue("processingFeePercentage", getMerchantAuthenticationResponse.processing_fee_percentage);
      setValue("convenienceFeeFlat", getMerchantAuthenticationResponse.convenience_fee_flat);
      setValue("convenienceFeeFlatMax", getMerchantAuthenticationResponse.convenience_fee_flat_max);
      setValue("convenienceFeeFlatMin", getMerchantAuthenticationResponse.convenience_fee_flat_min);
      setValue("convenienceFeePercentage", getMerchantAuthenticationResponse.convenience_fee_percentage);
      setValue("convenienceFeeType", getMerchantAuthenticationResponse.convenience_fee_type);
    }
  }, [editMode, getMerchantAuthenticationResponse, setValue]);

  useEffect(() => {
    if (editMode && updateMerchantAuthenticationStatus === 200) {
      navigate("/manage/merchant");
    } else if (createMerchantAuthenticationStatus === 200) {
      navigate("/manage/merchant");
    }
    return () => {
      resetCreateMerchantAuthenticationStatus();
      resetUpdateMerchantAuthenticationStatus();
    };
  }, [
    updateMerchantAuthenticationStatus,
    createMerchantAuthenticationStatus,
    editMode,
    navigate,
    resetUpdateMerchantAuthenticationStatus,
    resetCreateMerchantAuthenticationStatus,
  ]);
  return (
    <div className="mt-10">
      <Container className="flex justify-center overflow-hidden">
        <Box component="form" className="items-center shadow-2xl w-[55%] p-10 m-6">
          <p
            style={{
              textAlign: "center",
              paddingTop: "40px",
              fontSize: "25px",
            }}
            className="text-center font-bold"
          >
            {editMode ? "Update Merchant Authentication" : "Create Merchant Authentication"}
          </p>{" "}
          <div className="flex flex-col gap-4 m-5">
            <MerchantInformation
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              editMode={editMode}
              getMerchantAuthenticationResponse={getMerchantAuthenticationResponse}
            />
          </div>
        </Box>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    createMerchantAuthenticationStatus: state.merchant.createMerchantAuthenticationStatus,
    createMerchantAuthenticationLoading: state.merchant.createMerchantAuthenticationLoading,
    getMerchantAuthenticationResponse: state.merchant.getMerchantAuthenticationResponse,
    updateMerchantAuthenticationLoading: state.merchant.updateMerchantAuthenticationLoading,
    updateMerchantAuthenticationStatus: state.merchant.updateMerchantAuthenticationStatus,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    createMerchantAuthentication: (data, token) => {
      dispatch(createMerchantAuthentication(data, token));
    },
    resetCreateMerchantAuthenticationStatus: () => {
      dispatch(resetCreateMerchantAuthenticationStatus());
    },
    updateMerchantAuthentication: (data, id, token) => {
      dispatch(updateMerchantAuthentication(data, id, token));
    },
    resetUpdateMerchantAuthenticationStatus: () => {
      dispatch(resetUpdateMerchantAuthenticationStatus());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(CreateAndEditMerchant);
