import { SEND_NOTIFS_FAIL, SEND_NOTIFS_SUCCESS } from "../action/homeAction";

export const initialState = {
  status:undefined,
  sendNotificationsStatus: null,
  sendNotificationsResponse: null
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
      case "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY" : 
      return {
        ...state,
        status: action.payload.status,
        userPortfolioSummary: action.payload.data,
        msg: "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY"
      }
      
      case "REDIRECT_KYC_START_SUCCESS": 
        return {
          ...state,
          status: action.payload.status,
          kycStartSuccessResponse: action.payload,
          msg: "REDIRECT_KYC_START_SUCCESS"
        }
      case "REDIRECT_KYC_START_FAIL": 
        return {
          ...state,
          status: action.payload.status,
          kycStartFailResponse: action.payload,
          msg: "REDIRECT_KYC_START_FAIL"
        }          
      case "RESET_AND_REDIRECT_HOME":
        return {
          ...state,
          status:undefined,
          msg: "REDIRECT_HOME"
        } 

      case SEND_NOTIFS_SUCCESS:
         return {
        ...state,
        sendNotificationsResponse: action.payload.data,
        sendNotificationsStatus: action.payload.status
      }
      case SEND_NOTIFS_FAIL:
         return {
        ...state,
        sendNotificationsResponse: action.payload.data,
        sendNotificationsStatus: action.payload.status
      }
    default:
      return state;
  }
}
