import {
  CREATE_MERCHANT_COMPLETE,
  CREATE_MERCHANT_FAILURE,
  CREATE_MERCHANT_LOADING,
  CREATE_MERCHANT_SUCCESS,
  DELETE_MERCHANT_AUTHENTICATION_FAILURE,
  DELETE_MERCHANT_AUTHENTICATION_LOADING,
  DELETE_MERCHANT_AUTHENTICATION_LOADING_COMPLETE,
  DELETE_MERCHANT_AUTHENTICATION_SUCCESS,
  GET_MERCHANT_AUTHENTICATION_FAILURE,
  GET_MERCHANT_AUTHENTICATION_LOADING,
  GET_MERCHANT_AUTHENTICATION_LOADING_COMPLETE,
  GET_MERCHANT_AUTHENTICATION_SUCCESS,
  GET_MERCHANT_PROFILE_FAILURE,
  GET_MERCHANT_PROFILE_LOADING,
  GET_MERCHANT_PROFILE_LOADING_COMPLETE,
  GET_MERCHANT_PROFILE_SUCCESS,
  RESET_CREATE_MERCHANT_STATUS,
  RESET_DELETE_MERCHANT_AUTHENTICATION,
  RESET_GET_MERCHANT_AUTHENTICATION,
  RESET_GET_MERCHANT_PROFILE,
  RESET_UPDATE_MERCHANT_AUTHENTICATION_STATUS,
  UPDATE_MERCHANT_AUTHENTICATION_COMPLETE,
  UPDATE_MERCHANT_AUTHENTICATION_FAILURE,
  UPDATE_MERCHANT_AUTHENTICATION_LOADING,
  UPDATE_MERCHANT_AUTHENTICATION_SUCCESS,
  CREATE_MERCHANT_PROFILE_LOADING,
  CREATE_MERCHANT_PROFILE_COMPLETE,
  CREATE_MERCHANT_PROFILE_SUCCESS,
  CREATE_MERCHANT_PROFILE_FAILURE,
  RESET_CREATE_MERCHANT_PROFILE_STATUS,
  UPDATE_MERCHANT_PROFILE_LOADING,
  UPDATE_MERCHANT_PROFILE_COMPLETE,
  UPDATE_MERCHANT_PROFILE_SUCCESS,
  UPDATE_MERCHANT_PROFILE_FAILURE,
  RESET_UPDATE_MERCHANT_PROFILE_STATUS,
} from "../action/merchantAction";

export const initialState = {
  createMerchantAuthenticationStatus: null,
  createMerchantAuthenticationResponse: {},
  createMerchantAuthenticationLoading: false,
  getMerchantProfileLoading: false,
  getMerchantProfileResponse: [],
  getMerchantProfileStatus: null,
  getMerchantAuthenticationLoading: false,
  getMerchantAuthenticationResponse: {},
  getMerchantAuthenticationStatus: null,
  updateMerchantAuthenticationStatus: null,
  updateMerchantAuthenticationResponse: {},
  updateMerchantAuthenticationLoading: false,
  deleteMerchantAuthenticationLoading: false,
  deleteMerchantAuthenticationResponse: {},
  deleteMerchantAuthenticationStatus: null,
  createMerchantProfileResponse: {},
  createMerchantProfileStatus: null,
  createMerchantProfileLoading: false,
  updateMerchantProfileResponse: {},
  updateMerchantProfileStatus: null,
  updateMerchantProfileLoading: false,
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MERCHANT_LOADING:
      return {
        ...state,
        createMerchantAuthenticationLoading: true,
      };
    case CREATE_MERCHANT_COMPLETE:
      return {
        ...state,
        createMerchantAuthenticationLoading: false,
      };
    case CREATE_MERCHANT_SUCCESS:
      return {
        ...state,
        createMerchantAuthenticationResponse: action.payload.data,
        createMerchantAuthenticationStatus: action.payload.status,
      };
    case CREATE_MERCHANT_FAILURE:
      return {
        ...state,
        createMerchantAuthenticationResponse: action?.payload?.data,
        createMerchantAuthenticationStatus: action.payload.status,
      };
    case RESET_CREATE_MERCHANT_STATUS:
      return {
        ...state,
        createMerchantAuthenticationStatus: null,
      };
    case GET_MERCHANT_PROFILE_LOADING:
      return {
        ...state,
        getMerchantProfileLoading: true,
      };
    case GET_MERCHANT_PROFILE_LOADING_COMPLETE:
      return {
        ...state,
        getMerchantProfileLoading: false,
      };
    case GET_MERCHANT_PROFILE_SUCCESS:
      return {
        ...state,
        getMerchantProfileResponse: action.payload.data,
        getMerchantProfileStatus: action.payload.status,
      };
    case GET_MERCHANT_PROFILE_FAILURE:
      return {
        ...state,
        getMerchantProfileResponse: action.payload.data,
        getMerchantProfileStatus: action.payload.status,
      };
    case RESET_GET_MERCHANT_PROFILE:
      return {
        ...state,
        getMerchantProfileResponse: [],
        getMerchantProfileStatus: null,
      };

    case GET_MERCHANT_AUTHENTICATION_LOADING:
      return {
        ...state,
        getMerchantAuthenticationLoading: true,
      };
    case GET_MERCHANT_AUTHENTICATION_LOADING_COMPLETE:
      return {
        ...state,
        getMerchantAuthenticationLoading: false,
      };
    case GET_MERCHANT_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        getMerchantAuthenticationResponse: action.payload.data,
        getMerchantAuthenticationStatus: action.payload.status,
      };
    case GET_MERCHANT_AUTHENTICATION_FAILURE:
      return {
        ...state,
        getMerchantAuthenticationResponse: action.payload.data,
        getMerchantAuthenticationStatus: action.payload.status,
      };
    case RESET_GET_MERCHANT_AUTHENTICATION:
      return {
        ...state,
        // getMerchantAuthenticationResponse: {}, commenting because we need status to be cleared and not response in this particular case
        getMerchantAuthenticationStatus: null,
      };

    case UPDATE_MERCHANT_AUTHENTICATION_LOADING:
      return {
        ...state,
        updateMerchantAuthenticationLoading: true,
      };
    case UPDATE_MERCHANT_AUTHENTICATION_COMPLETE:
      return {
        ...state,
        updateMerchantAuthenticationLoading: false,
      };
    case UPDATE_MERCHANT_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        updateMerchantAuthenticationResponse: action.payload.data,
        updateMerchantAuthenticationStatus: action.payload.status,
      };
    case UPDATE_MERCHANT_AUTHENTICATION_FAILURE:
      return {
        ...state,
        updateMerchantAuthenticationResponse: action?.payload?.data,
        updateMerchantAuthenticationStatus: action.payload.status,
      };
    case RESET_UPDATE_MERCHANT_AUTHENTICATION_STATUS:
      return {
        ...state,
        updateMerchantAuthenticationStatus: null,
      };

    case DELETE_MERCHANT_AUTHENTICATION_LOADING:
      return {
        ...state,
        deleteMerchantAuthenticationLoading: true,
      };
    case DELETE_MERCHANT_AUTHENTICATION_LOADING_COMPLETE:
      return {
        ...state,
        deleteMerchantAuthenticationLoading: false,
      };
    case DELETE_MERCHANT_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        deleteMerchantAuthenticationResponse: action.payload.data,
        deleteMerchantAuthenticationStatus: action.payload.status,
      };
    case DELETE_MERCHANT_AUTHENTICATION_FAILURE:
      return {
        ...state,
        deleteMerchantAuthenticationResponse: action.payload.data,
        deleteMerchantAuthenticationStatus: action.payload.status,
      };
    case RESET_DELETE_MERCHANT_AUTHENTICATION:
      return {
        ...state,
        deleteMerchantAuthenticationResponse: {},
        deleteMerchantAuthenticationStatus: null,
      };
    case CREATE_MERCHANT_PROFILE_LOADING:
      return {
        ...state,
        createMerchantProfileLoading: true,
      };
    case CREATE_MERCHANT_PROFILE_SUCCESS:
      return {
        ...state,
        createMerchantProfileResponse: action.payload.data,
        createMerchantProfileStatus: action.payload.status,
      };
    case CREATE_MERCHANT_PROFILE_COMPLETE:
      return {
        ...state,
        createMerchantProfileComplete: false,
      };
    case CREATE_MERCHANT_PROFILE_FAILURE:
      return {
        ...state,
        createMerchantProfileResponse: action.payload.data,
        createMerchantProfileStatus: action.payload.status,
      };
    case RESET_CREATE_MERCHANT_PROFILE_STATUS:
      return {
        ...state,
        createMerchantProfileStatus: null,
      };
    case UPDATE_MERCHANT_PROFILE_LOADING:
      return {
        ...state,
        updateMerchantProfileLoading: true,
      };
    case UPDATE_MERCHANT_PROFILE_SUCCESS:
      return {
        ...state,
        updateMerchantProfileResponse: action.payload.data,
        updateMerchantProfileStatus: action.payload.status,
      };
    case UPDATE_MERCHANT_PROFILE_COMPLETE:
      return {
        ...state,
        updateMerchantProfileComplete: false,
      };
    case UPDATE_MERCHANT_PROFILE_FAILURE:
      return {
        ...state,
        updateMerchantProfileResponse: action.payload.data,
        updateMerchantProfileStatus: action.payload.status,
      };
    case RESET_UPDATE_MERCHANT_PROFILE_STATUS:
      return {
        ...state,
        updateMerchantProfileStatus: null,
      };

    default:
      return state;
  }
}
