export const CREATE_MERCHANT_LOADING = "CREATE_MERCHANT_LOADING";
export const CREATE_MERCHANT_COMPLETE = "CREATE_MERCHANT_COMPLETE";
export const CREATE_MERCHANT_SUCCESS = "CREATE_MERCHANT_SUCCESS";
export const CREATE_MERCHANT_FAILURE = "CREATE_MERCHANT_FAILURE";

// Create merchant payload
export const CREATE_MERCHANT_PROFILE_LOADING = "CREATE_MERCHANT_PROFILE_LOADING";
export const CREATE_MERCHANT_PROFILE_COMPLETE = "CREATE_MERCHANT_PROFILE_COMPLETE";
export const CREATE_MERCHANT_PROFILE_SUCCESS = "CREATE_MERCHANT_PROFILE_SUCCESS";
export const CREATE_MERCHANT_PROFILE_FAILURE = "CREATE_MERCHANT_PROFILE_FAILURE";
export const RESET_CREATE_MERCHANT_PROFILE_STATUS = "RESET_CREATE_MERCHANT_PROFILE_STATUS";
// Create mercahant payload end

// Update merchant payload
export const UPDATE_MERCHANT_PROFILE_LOADING = "UPDATE_MERCHANT_PROFILE_LOADING";
export const UPDATE_MERCHANT_PROFILE_COMPLETE = "UPDATE_MERCHANT_PROFILE_COMPLETE";
export const UPDATE_MERCHANT_PROFILE_SUCCESS = "UPDATE_MERCHANT_PROFILE_SUCCESS";
export const UPDATE_MERCHANT_PROFILE_FAILURE = "UPDATE_MERCHANT_PROFILE_FAILURE";
export const RESET_UPDATE_MERCHANT_PROFILE_STATUS = "RESET_UPDATE_MERCHANT_PROFILE_STATUS";
// Update mercahant payload end

export const RESET_CREATE_MERCHANT_STATUS = "RESET_CREATE_MERCHANT_STATUS";
export const GET_MERCHANT_PROFILE_LOADING = "GET_MERCHANT_PROFILE_LOADING";
export const GET_MERCHANT_PROFILE_LOADING_COMPLETE = "GET_MERCHANT_PROFILE_LOADING_COMPLETE";
export const GET_MERCHANT_PROFILE_SUCCESS = "GET_MERCHANT_PROFILE_SUCCESS";
export const GET_MERCHANT_PROFILE_FAILURE = "GET_MERCHANT_PROFILE_FAILURE";
export const RESET_GET_MERCHANT_PROFILE = "RESET_GET_MERCHANT_PROFILE";

export const GET_MERCHANT_AUTHENTICATION_LOADING = "GET_MERCHANT_AUTHENTICATION_LOADING";
export const GET_MERCHANT_AUTHENTICATION_LOADING_COMPLETE = "GET_MERCHANT_AUTHENTICATION_LOADING_COMPLETE";
export const GET_MERCHANT_AUTHENTICATION_SUCCESS = "GET_MERCHANT_AUTHENTICATION_SUCCESS";
export const GET_MERCHANT_AUTHENTICATION_FAILURE = "GET_MERCHANT_AUTHENTICATION_FAILURE";
export const RESET_GET_MERCHANT_AUTHENTICATION = "RESET_GET_MERCHANT_AUTHENTICATION";

export const UPDATE_MERCHANT_AUTHENTICATION_LOADING = "UPDATE_MERCHANT_AUTHENTICATION_LOADING";
export const UPDATE_MERCHANT_AUTHENTICATION_COMPLETE = "UPDATE_MERCHANT_AUTHENTICATION_COMPLETE";
export const UPDATE_MERCHANT_AUTHENTICATION_SUCCESS = "UPDATE_MERCHANT_AUTHENTICATION_SUCCESS";
export const UPDATE_MERCHANT_AUTHENTICATION_FAILURE = "UPDATE_MERCHANT_AUTHENTICATION_FAILURE";
export const RESET_UPDATE_MERCHANT_AUTHENTICATION_STATUS = "RESET_UPDATE_MERCHANT_AUTHENTICATION_STATUS";

export const DELETE_MERCHANT_AUTHENTICATION_LOADING = "DELETE_MERCHANT_AUTHENTICATION_LOADING";
export const DELETE_MERCHANT_AUTHENTICATION_LOADING_COMPLETE = "DELETE_MERCHANT_AUTHENTICATION_LOADING_COMPLETE";
export const DELETE_MERCHANT_AUTHENTICATION_SUCCESS = "DELETE_MERCHANT_AUTHENTICATION_SUCCESS";
export const DELETE_MERCHANT_AUTHENTICATION_FAILURE = "DELETE_MERCHANT_AUTHENTICATION_FAILURE";
export const RESET_DELETE_MERCHANT_AUTHENTICATION = "RESET_DELETE_MERCHANT_AUTHENTICATION";
export function createMerchantAuthenticationLoading() {
  return {
    type: CREATE_MERCHANT_LOADING,
  };
}

export function createMerchantAuthenticationComplete() {
  return {
    type: CREATE_MERCHANT_COMPLETE,
  };
}
export function createMerchantAuthenticationFailure(response) {
  return {
    type: CREATE_MERCHANT_FAILURE,
    payload: response,
  };
}

export function createMerchantAuthenticationSuccess(response) {
  return {
    type: CREATE_MERCHANT_SUCCESS,
    payload: response,
  };
}

export function resetCreateMerchantAuthenticationStatus() {
  return {
    type: RESET_CREATE_MERCHANT_STATUS,
  };
}
export function getMerchantProfileLoading() {
  return {
    type: GET_MERCHANT_PROFILE_LOADING,
  };
}

export function getMerchantProfileLoadingComplete() {
  return {
    type: GET_MERCHANT_PROFILE_LOADING_COMPLETE,
  };
}

export function getMerchantProfileSuccess(response) {
  return {
    type: GET_MERCHANT_PROFILE_SUCCESS,
    payload: response,
  };
}

export function getMerchantProfileFailure(response) {
  return {
    type: GET_MERCHANT_PROFILE_FAILURE,
    payload: response,
  };
}

export const resetMerchantProfile = () => {
  return {
    type: RESET_GET_MERCHANT_PROFILE,
  };
};

export function getMerchantAuthenticationLoading() {
  return {
    type: GET_MERCHANT_AUTHENTICATION_LOADING,
  };
}

export function getMerchantAuthenticationLoadingComplete() {
  return {
    type: GET_MERCHANT_AUTHENTICATION_LOADING_COMPLETE,
  };
}

export function getMerchantAuthenticationSuccess(response) {
  return {
    type: GET_MERCHANT_AUTHENTICATION_SUCCESS,
    payload: response,
  };
}

export function getMerchantAuthenticationFailure(response) {
  return {
    type: GET_MERCHANT_AUTHENTICATION_FAILURE,
    payload: response,
  };
}

export const resetGetMerchantAuthentication = () => {
  return {
    type: RESET_GET_MERCHANT_AUTHENTICATION,
  };
};
export function updateMerchantAuthenticationLoading() {
  return {
    type: UPDATE_MERCHANT_AUTHENTICATION_LOADING,
  };
}

export function updateMerchantAuthenticationComplete() {
  return {
    type: UPDATE_MERCHANT_AUTHENTICATION_COMPLETE,
  };
}
export function updateMerchantAuthenticationFailure() {
  return {
    type: UPDATE_MERCHANT_AUTHENTICATION_FAILURE,
  };
}

export function updateMerchantAuthenticationSuccess(response) {
  return {
    type: UPDATE_MERCHANT_AUTHENTICATION_SUCCESS,
    payload: response,
  };
}

export function resetUpdateMerchantAuthenticationStatus() {
  return {
    type: RESET_UPDATE_MERCHANT_AUTHENTICATION_STATUS,
  };
}

export function deleteMerchantAuthenticationLoading() {
  return {
    type: DELETE_MERCHANT_AUTHENTICATION_LOADING,
  };
}

export function deleteMerchantAuthenticationLoadingComplete() {
  return {
    type: DELETE_MERCHANT_AUTHENTICATION_LOADING_COMPLETE,
  };
}

export function deleteMerchantAuthenticationSuccess(response) {
  return {
    type: DELETE_MERCHANT_AUTHENTICATION_SUCCESS,
    payload: response,
  };
}

export function deleteMerchantAuthenticationFailure(response) {
  return {
    type: DELETE_MERCHANT_AUTHENTICATION_FAILURE,
    payload: response,
  };
}

export const resetDeleteMerchantAuthentication = () => {
  return {
    type: RESET_DELETE_MERCHANT_AUTHENTICATION,
  };
};

// Create Merchant Profile Information
export function createMerchantProfileLoading() {
  return {
    type: CREATE_MERCHANT_PROFILE_LOADING,
  };
}

export function createMerchantProfileComplete() {
  return {
    type: CREATE_MERCHANT_PROFILE_COMPLETE,
  };
}

export function createMerchantProfileSuccess(response) {
  return {
    type: CREATE_MERCHANT_PROFILE_SUCCESS,
    payload: response,
  };
}

export function createMerchantProfileFailure(response) {
  return {
    type: CREATE_MERCHANT_PROFILE_FAILURE,
    payload: response,
  };
}

export function resetCreateMerchantProfileStatus() {
  return {
    type: RESET_CREATE_MERCHANT_PROFILE_STATUS,
  };
}
// Update Mercahant Profile Information
export function updateMerchantProfileLoading() {
  return {
    type: UPDATE_MERCHANT_PROFILE_LOADING,
  };
}

export function updateMerchantProfileComplete() {
  return {
    type: UPDATE_MERCHANT_PROFILE_COMPLETE,
  };
}
export function updateMerchantProfileFailure() {
  return {
    type: UPDATE_MERCHANT_PROFILE_FAILURE,
  };
}

export function updateMerchantProfileSuccess(response) {
  return {
    type: UPDATE_MERCHANT_PROFILE_SUCCESS,
    payload: response,
  };
}

export function resetUpdateMerchantProfileStatus() {
  return {
    type: RESET_UPDATE_MERCHANT_PROFILE_STATUS,
  };
}
