import {
  createMerchantAuthenticationSuccess,
  createMerchantAuthenticationFailure,
  createMerchantAuthenticationComplete,
  createMerchantAuthenticationLoading,
  getMerchantProfileFailure,
  getMerchantProfileLoading,
  getMerchantProfileLoadingComplete,
  getMerchantProfileSuccess,
  getMerchantAuthenticationFailure,
  getMerchantAuthenticationLoading,
  getMerchantAuthenticationLoadingComplete,
  getMerchantAuthenticationSuccess,
  updateMerchantAuthenticationLoading,
  updateMerchantAuthenticationSuccess,
  updateMerchantAuthenticationFailure,
  updateMerchantAuthenticationComplete,
  deleteMerchantAuthenticationFailure,
  deleteMerchantAuthenticationLoading,
  deleteMerchantAuthenticationLoadingComplete,
  deleteMerchantAuthenticationSuccess,
  createMerchantProfileLoading,
  createMerchantProfileSuccess,
  createMerchantProfileFailure,
  createMerchantProfileComplete,
  updateMerchantProfileLoading,
  updateMerchantProfileSuccess,
  updateMerchantProfileFailure,
  updateMerchantProfileComplete,
} from "../action/merchantAction";
import { API } from "./api";
import { API_ENDPOINTS } from "./api-endpoint";

export function createMerchantAuthentication(data, token) {
  return (dispatch) => {
    dispatch(createMerchantAuthenticationLoading());
    API.request({
      method: "POST",
      url: `${API_ENDPOINTS.MERCHANT_AUTHENTICATON}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(createMerchantAuthenticationSuccess(response));
      })
      .catch((error) => {
        dispatch(createMerchantAuthenticationFailure(error));
      })
      .finally(() => {
        dispatch(createMerchantAuthenticationComplete());
      });
  };
}

export function getMerchantProfile() {
  return (dispatch) => {
    dispatch(getMerchantProfileLoading());
    API.request({
      method: "GET",
      url: `${API_ENDPOINTS.MERCHANT_PROFILE}`,
    })
      .then((response) => {
        dispatch(getMerchantProfileSuccess(response));
      })
      .catch((error) => {
        dispatch(getMerchantProfileFailure(error));
      })
      .finally(() => {
        dispatch(getMerchantProfileLoadingComplete());
      });
  };
}

export function getMerchantAuthentication(id) {
  return (dispatch) => {
    dispatch(getMerchantAuthenticationLoading());
    API.request({
      method: "GET",
      url: `${API_ENDPOINTS.MERCHANT_AUTHENTICATON}/${id}`,
    })
      .then((response) => {
        dispatch(getMerchantAuthenticationSuccess(response));
      })
      .catch((error) => {
        dispatch(getMerchantAuthenticationFailure(error));
      })
      .finally(() => {
        dispatch(getMerchantAuthenticationLoadingComplete());
      });
  };
}

export function updateMerchantAuthentication(data, id, token) {
  return (dispatch) => {
    dispatch(updateMerchantAuthenticationLoading());
    API.request({
      method: "PUT",
      url: `${API_ENDPOINTS.MERCHANT_AUTHENTICATON}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(updateMerchantAuthenticationSuccess(response));
      })
      .catch((error) => {
        dispatch(updateMerchantAuthenticationFailure(error));
      })
      .finally(() => {
        dispatch(updateMerchantAuthenticationComplete());
      });
  };
}

export function deleteMerchantAuthentication(id) {
  return (dispatch) => {
    dispatch(deleteMerchantAuthenticationLoading());
    API.request({
      method: "DELETE",
      url: `${API_ENDPOINTS.MERCHANT_AUTHENTICATON}/${id}`,
    })
      .then((response) => {
        dispatch(deleteMerchantAuthenticationSuccess(response));
      })
      .catch((error) => {
        dispatch(deleteMerchantAuthenticationFailure(error));
      })
      .fiOnally(() => {
        dispatch(deleteMerchantAuthenticationLoadingComplete());
      });
  };
}

// Create Mercahant Profile
export function createMerchantProfile(data, token) {
  return (dispatch) => {
    dispatch(createMerchantProfileLoading());
    API.request({
      method: "POST",
      url: `${API_ENDPOINTS.MERCHANT_PROFILE}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(createMerchantProfileSuccess(response));
      })
      .catch((error) => {
        dispatch(createMerchantProfileFailure(error));
      })
      .finally(() => {
        dispatch(createMerchantProfileComplete());
      });
  };
}

export function updateMerchantProfile(data, id, token) {
  return (dispatch) => {
    dispatch(updateMerchantProfileLoading());
    API.request({
      method: "PUT",
      url: `${API_ENDPOINTS.MERCHANT_PROFILE}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(updateMerchantProfileSuccess(response));
      })
      .catch((error) => {
        dispatch(updateMerchantProfileFailure(error));
      })
      .finally(() => {
        dispatch(updateMerchantProfileComplete());
      });
  };
}
