
import {
  responseFail,
} from "../action/authAction";
import {
  getPortfolioSummarySuccess,
  kycStartSuccess,
  kycStartFail,
  sendNotificationsSuccess,
  sendNotificationsFail,
} from '../action/homeAction.js';
import { API } from "./api";
import {API_ENDPOINTS} from "./api-endpoint";


export function requestSummary() {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.WALLET_SUMMARY}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getPortfolioSummarySuccess(response));
  }).catch((error) => {
    dispatch(responseFail(false));
  });
}
export function customerKycCheck(data) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.KYC_CHECK}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      exchange_provider: 'pi',
    },
    data: data,
  }).then(response => {
    dispatch(kycStartSuccess(response));
  }).catch((error) => {
    dispatch(kycStartFail(error));
  });
}
export function sendNotifications(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.NOTIFY}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
    data: data,
  }).then(response => {
    dispatch(sendNotificationsSuccess(response));
  }).catch((error) => {
    dispatch(sendNotificationsFail(error));
  });
}
