import React from "react";
import { connect } from "react-redux";
import { AppBar, Container, Toolbar, Typography } from "@mui/material";
const ResponsiveAppBar = (props) => {
  return (
    <AppBar position="fixed" style={{ background: "#ffffff" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              width={170}
              height={40}
              src={process.env.PUBLIC_URL + "/images/coin-icons/payspaze_logo.png"}
              alt="logo"
            />
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStatetoProps = (state) => {
  return {
    userPortfolioSummary: state.home.userPortfolioSummary,
    userdata: state.auth.userdata,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {};
};
export default connect(mapStatetoProps, mapDispatchtoProps)(ResponsiveAppBar);
