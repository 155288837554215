import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getForwardForRequestLogs } from "../../redux/services/paymentIntentServices";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const ForwardForRequestLogs = ({ token, getForwardForRequestLogs, getForwardForRequestLogsResponse }) => {
  const { state } = useLocation();
  const forwardFor = state?.forwardFor;

  useEffect(() => {
    if (token) {
      getForwardForRequestLogs(forwardFor, token);
    }
  }, [getForwardForRequestLogs, token, forwardFor]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/request-logs");
  };
  return (
    <>
      <div onClick={handleCancel} className="mt-4 normal-case   cursor-pointer flex gap-2">
        <span className="hover:bg-[#EFEBE9] rounded">
          <KeyboardBackspaceIcon />
        </span>
        <span className="text-sm mt-1 text-blue-500">Back To Request Log</span>
      </div>
      <div className="mt-10 flex justify-center font-semibold text-2xl">Request Log Filter By IP</div>
      <TableContainer component={Card} className="mt-6 min-w-[100px] max-w-[100%]">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="font-bold">Timestamp</TableCell>
              <TableCell className="font-bold">IP</TableCell>
              <TableCell className="font-bold">Path</TableCell>
              <TableCell className="font-bold">Referer</TableCell>
              <TableCell className="font-bold">User Agent</TableCell>
              <TableCell className="font-bold">Ref</TableCell>
              <TableCell className="font-bold">ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getForwardForRequestLogsResponse && getForwardForRequestLogsResponse?.length > 0 ? (
              getForwardForRequestLogsResponse
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{moment(request.received_timestamp).format("MM/DD/YYYY HH:mm:ss")}</TableCell>
                    <TableCell>{request.xforwarded_for}</TableCell>
                    <TableCell>{request.servlet_path}</TableCell>
                    <TableCell>{request.referer}</TableCell>
                    <TableCell>
                      {request.user_agent ? (
                        <Tooltip title={request.user_agent}>
                          <span>{request.user_agent.slice(0, 5)}...</span>
                        </Tooltip>
                      ) : null}
                    </TableCell>
                    {request.ref ? (
                      <TableCell>
                        <Tooltip title={request.ref ?? ""}>
                          <span>{request.ref?.slice(0, 5)}...</span>
                        </Tooltip>
                      </TableCell>
                    ) : null}
                    {request.id ? (
                      <TableCell>
                        <Tooltip title={request.id ?? ""}>
                          <span>{request.id?.slice(0, 5)}...</span>
                        </Tooltip>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>No Forward For request logs available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 25, 50]}
          component="div"
          count={getForwardForRequestLogsResponse?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    getForwardForRequestLogsResponse: state.paymentIntent.getForwardForRequestLogsResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForwardForRequestLogs: (forwardFor, token) => {
      dispatch(getForwardForRequestLogs(forwardFor, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForwardForRequestLogs);
