import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getKnowIps } from "../../redux/services/paymentIntentServices";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const ManageKnownIps = ({ getKnowIps, getKnowIpsResponse, token }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      getKnowIps(token);
    }
    return () => {};
  }, [token, getKnowIps]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleKnownIpsSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dataArray = Array.isArray(getKnowIpsResponse) ? getKnowIpsResponse : [];

  const sortedKnownIpsData = [...dataArray].sort((a, b) => {
    if (orderBy === "ip") {
      return (order === "asc" ? 1 : -1) * a.ip.localeCompare(b.ip);
    }
    if (orderBy === "known_ip_id") {
      return (order === "asc" ? 1 : -1) * (a.known_ip_id - b.known_ip_id);
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className="mt-10 flex justify-center font-semibold text-2xl">Known Ips</div>
      <div className="flex flex-row-reverse justify-start items-center mb-8 mt-11">
        <Button
          variant="contained"
          color="primary"
          className="normal-case"
          onClick={() => navigate("/create-know-ips")}
          startIcon={<AddCircleOutlineIcon />}
        >
          Create Known Ip
        </Button>
      </div>
      <TableContainer component={Card} className="mt-6">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "ip"}
                  direction={orderBy === "ip" ? order : "asc"}
                  onClick={() => handleKnownIpsSort("ip")}
                  className="font-bold"
                  align="center"
                >
                  IP
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "known_ip_id"}
                  direction={orderBy === "known_ip_id" ? order : "asc"}
                  onClick={() => handleKnownIpsSort("known_ip_id")}
                  className="font-bold"
                  align="center"
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" className="font-bold">
                Blocked
              </TableCell>
              <TableCell align="center" className="font-bold">
                User Type
              </TableCell>
              <TableCell align="center" className="font-bold">
                Owner
              </TableCell>
              <TableCell align="left" className="font-bold">
                Location
              </TableCell>
              <TableCell align="center" className="font-bold">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedKnownIpsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((knownips, index) => (
              <TableRow key={knownips.id + index}>
                <TableCell>{knownips.ip}</TableCell>
                <TableCell>{knownips.known_ip_id}</TableCell>
                <TableCell align="center">{knownips.blocked ? "Yes" : "No"}</TableCell>
                <TableCell>{knownips.user_type}</TableCell>
                <TableCell>{knownips.owner_name}</TableCell>
                <TableCell>{knownips.location}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => navigate("/create-know-ips", { state: { knowIpId: knownips, editMode: true } })}
                      className="normal-case"
                      startIcon={<EditIcon />}
                    >
                      Edit IP
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 25, 50]}
          component="div"
          count={sortedKnownIpsData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    getKnowIpsStatus: state.paymentIntent.getKnowIpsStatus,
    getKnowIpsResponse: state.paymentIntent.getKnowIpsResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getKnowIps: (token) => {
      dispatch(getKnowIps(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageKnownIps);
