import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Box, Button, Container, TextField, Snackbar } from "@mui/material";

import { customerKycCheck } from "../redux/services/homeServices";
import ResponsiveAppBar from "./ResponsiveAppBar";
import { resetHomeState } from "../redux/action/homeAction";

const Home = ({ callCustomerKycCheck }) => {
  const [userEmail, setUserEmail] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const handleChange = (event) => {
    setUserEmail(event.target.value);
  };

  const submitForm = () => {
    const request = [userEmail];
    callCustomerKycCheck(request);
    setMessage("Request sent");
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ResponsiveAppBar />
      <div className="login_wrapper login_wrapper-bottom-margin">
        <Container maxWidth="sm">
          <Box
            component="form"
            className="coinspaze-form"
            sx={{ p: "20px", borderRadius: "20px", border: 1, mt: "20%", borderColor: "gray" }}
          >
            <p style={{ textAlign: "center", paddingTop: "40px", fontSize: "32px" }}>Trigger User KYC</p>
            <TextField
              id="outlined-disabled"
              sx={{ width: "100%", borderRadius: "10px", marginTop: "10px", marginBottom: "10px" }}
              label="User Email"
              onChange={handleChange}
            />
            <Box sx={{ textAlign: "center" }}>
              <Button
                sx={{
                  width: "40%",
                  marginTop: "10px",
                  marginBottom: "10px",
                  color: "black",
                  background: "linear-gradient(180deg, #FED3A5 10%, #D461E6 100%)",
                  borderRadius: "10px",
                }}
                variant="contained"
                onClick={submitForm}
              >
                S e n d
              </Button>
            </Box>
          </Box>
        </Container>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message={message} />
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    userdata: state.auth.userdata,
    homeStatusCode: state.home.status,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callCustomerKycCheck: function (request) {
      dispatch(resetHomeState());
      dispatch(customerKycCheck(request));
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Home);
