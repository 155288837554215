import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { MainContainer } from "./component/MainContainer";
import SideDrawer from "./component/SideDrawer";
import { setToken } from "./redux/action/authAction";

const theme = (mode) =>
  createTheme({
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#000000",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundColor: "#7254D0",
            "&:hover": {
              backgroundColor: "#6243c4",
            },
          },
          containedError: {
            backgroundColor: "#d32f2f",
            "&:hover": {
              backgroundColor: "#d32f2f",
            },
          },
          outlinedError: {
            color: "#d32f2f",
          },
        },
      },
    },
  });

function App(props) {
  const [themeConfig] = useState("light");
  const { callSetToken } = props;

  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `coinspaze-api`,
          scope: "openid profile email admin",
        });
        callSetToken(accessToken);
      } catch (e) {
        loginWithRedirect();
      }
    };
    getUserMetadata();
  }, [getAccessTokenSilently, callSetToken, loginWithRedirect]);

  return (
    <>
      <ThemeProvider theme={theme(themeConfig)}>
          <BrowserRouter>
            <SideDrawer>
              <MainContainer />
            </SideDrawer>
          </BrowserRouter>
      </ThemeProvider>
    </>
  );
}
const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    callSetToken: function (token) {
      dispatch(setToken(token));
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(App);
