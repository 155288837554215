import {
  getPaymentIntentFailure,
  getPaymentIntentLoading,
  getPaymentIntentLoadingComplete,
  getPaymentIntentSuccess,
  getRequestLogsFailure,
  getRequestLogsLoading,
  getRequestLogsLoadingComplete,
  getRequestLogsSuccess,
  getKnowIpsFailure,
  getKnowIpsLoading,
  getKnowIpsLoadingComplete,
  getKnowIpsSuccess,
  createKnowIpsSuccess,
  createKnowIpsFailure,
  createKnowIpsLoadingComplete,
  createKnowIpsLoading,
  updateKnownIpsLoading,
  updateKnownIpsSuccess,
  updateKnownIpsFailure,
  updateKnownIpsLoadingComplete,
  getRefRequestLogsFailure,
  getRefRequestLogsLoading,
  getRefRequestLogsLoadingComplete,
  getRefRequestLogsSuccess,
  getForwardForRequestLogsFailure,
  getForwardForRequestLogsLoading,
  getForwardForRequestLogsLoadingComplete,
  getForwardForRequestLogsSuccess,
} from "../action/paymentIntentAction";
import { API } from "./api";
import { API_ENDPOINTS } from "./api-endpoint";

export function getPaymentIntent(token) {
  return (dispatch) => {
    dispatch(getPaymentIntentLoading());
    API.request({
      method: "GET",
      url: `${API_ENDPOINTS.PAYMENT_INTENT}`,
      headers: {
        Authorization: `Bearer ${token}`,
        exchange_provider: "pi",
      },
    })
      .then((response) => {
        dispatch(getPaymentIntentSuccess(response));
      })
      .catch((error) => {
        dispatch(getPaymentIntentFailure(error));
      })
      .finally(() => {
        dispatch(getPaymentIntentLoadingComplete());
      });
  };
}

export function getRequestLogs(token) {
  return (dispatch) => {
    dispatch(getRequestLogsLoading());
    API.request({
      method: "GET",
      url: `${API_ENDPOINTS.REQUEST_LOGS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        exchange_provider: "pi",
      },
    })
      .then((response) => {
        dispatch(getRequestLogsSuccess(response));
      })
      .catch((error) => {
        dispatch(getRequestLogsFailure(error));
      })
      .finally(() => {
        dispatch(getRequestLogsLoadingComplete());
      });
  };
}

export function getRefRequestLogs(ref, token) {
  return (dispatch) => {
    dispatch(getRefRequestLogsLoading());
    API.request({
      method: "GET",
      url: `${API_ENDPOINTS.REQUEST_LOGS}?ref=${ref}`,
      headers: {
        Authorization: `Bearer ${token}`,
        exchange_provider: "pi",
      },
    })
      .then((response) => {
        dispatch(getRefRequestLogsSuccess(response));
      })
      .catch((error) => {
        dispatch(getRefRequestLogsFailure(error));
      })
      .finally(() => {
        dispatch(getRefRequestLogsLoadingComplete());
      });
  };
}

export function getForwardForRequestLogs(forwardFor, token) {
  console.log("url", `${API_ENDPOINTS.REQUEST_LOGS}?forwarded_for=${forwardFor}`);
  return (dispatch) => {
    dispatch(getForwardForRequestLogsLoading());
    API.request({
      method: "GET",
      url: `${API_ENDPOINTS.REQUEST_LOGS}?forwarded_for=${forwardFor}`,
      headers: {
        Authorization: `Bearer ${token}`,
        exchange_provider: "pi",
      },
    })
      .then((response) => {
        dispatch(getForwardForRequestLogsSuccess(response));
      })
      .catch((error) => {
        dispatch(getForwardForRequestLogsFailure(error));
      })
      .finally(() => {
        dispatch(getForwardForRequestLogsLoadingComplete());
      });
  };
}

export function getKnowIps(token) {
  return (dispatch) => {
    dispatch(getKnowIpsLoading());
    API.request({
      method: "GET",
      url: `${API_ENDPOINTS.KNOW_IPS}?blocked=false`,
      headers: {
        Authorization: `Bearer ${token}`,
        exchange_provider: "pi",
      },
    })
      .then((response) => {
        dispatch(getKnowIpsSuccess(response));
      })
      .catch((error) => {
        dispatch(getKnowIpsFailure(error));
      })
      .finally(() => {
        dispatch(getKnowIpsLoadingComplete());
      });
  };
}

export function createKnowIps(data, token) {
  return (dispatch) => {
    dispatch(createKnowIpsLoading());
    API.request({
      method: "POST",
      url: `${API_ENDPOINTS.KNOW_IPS}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(createKnowIpsSuccess(response));
      })
      .catch((error) => {
        dispatch(createKnowIpsFailure(error));
      })
      .finally(() => {
        dispatch(createKnowIpsLoadingComplete());
      });
  };
}

export function updateKnownIps(data, id, token) {
  return (dispatch) => {
    dispatch(updateKnownIpsLoading());
    API.request({
      method: "PUT",
      url: `${API_ENDPOINTS.KNOW_IPS}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(updateKnownIpsSuccess(response));
      })
      .catch((error) => {
        dispatch(updateKnownIpsFailure(error));
      })
      .finally(() => {
        dispatch(updateKnownIpsLoadingComplete());
      });
  };
}
