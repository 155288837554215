import BusinessIcon from "@mui/icons-material/Business";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import SecurityIcon from "@mui/icons-material/Security";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const menuList = [
  {
    name: "Home",
    icon: HomeIcon,
    redirect: "/payment-intent",
    hasSubList: false,
    isListItemOpen: false,
  },
  {
    name: "Merchants",
    icon: BusinessIcon,
    redirect: "/manage/merchant",
    hasSubList: false,
    isListItemOpen: false,
  },

  {
    name: "Notification",
    icon: NotificationsActiveIcon,
    redirect: "/notification",
    hasSubList: false,
    isListItemOpen: false,
  },
  {
    name: "Payment Intents",
    icon: ShoppingCartCheckoutIcon,
    redirect: "/payment-intent",
    hasSubList: false,
    isListItemOpen: false,
  },
  {
    name: "Request Logs",
    icon: ViewTimelineIcon,
    redirect: "/request-logs",
    hasSubList: false,
    isListItemOpen: false,
  },
  {
    name: "Know Ips",
    icon: PersonPinIcon,
    redirect: "/know-ips",
    hasSubList: false,
    isListItemOpen: false,
  },
  {
    name: "Trigger KYC",
    icon: SecurityIcon,
    redirect: "/",
    hasSubList: false,
    isListItemOpen: false,
  },
];

export const DisplayDrawerMenu = ({ openDrawer, handleCloseUserMenu }) => {
  const [menu, setMenu] = useState(menuList);
  const location = useLocation();
  const path = location.pathname;
  const handleMenuItemOpen = (index) => {
    const newItems = [...menu];
    newItems[index].isListItemOpen = !newItems[index].isListItemOpen;
    setMenu(newItems);
  };

  const closeSubMenuItems = () => {
    const newItems = menu.map((menuItem) => ({
      ...menuItem,
      isListItemOpen: false,
    }));
    setMenu(newItems);
  };
  useEffect(() => {
    if (openDrawer === false) {
      closeSubMenuItems();
    }
  }, [openDrawer]);
  return (
    <>
      {menu.map((menuItem, index) => (
        <ListItem
          key={menuItem.name}
          disablePadding
          sx={{ display: "block" }}
          className={`p-0 ${path === menuItem.redirect ? "bg-gradient-to-r from-[#fff] to-[#fcc8e8]" : ""}`}
        >
          {menuItem.hasSubList ? (
            <>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? "initial" : "center",
                }}
                onClick={() => handleMenuItemOpen(index)}
                className="px-6 w-[100%]"
                disabled={menuItem.disabled}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 1 : "auto",
                    justifyContent: "center",
                  }}
                  className="bg-gradient-to-r from-[#7254D0] to-[#FF94D5] rounded-full p-2"
                >
                  {<menuItem.icon style={{ color: "white" }} />}
                </ListItemIcon>
                <ListItemText primary={menuItem?.name} sx={{ opacity: openDrawer ? 1 : 0 }} />
                {openDrawer && <>{menuItem?.isListItemOpen ? <ExpandLess /> : <ExpandMore />}</>}
              </ListItemButton>
              <Collapse in={menuItem?.isListItemOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  {menuItem.subList.map((subListItem) => (
                    <ListItemButton
                      key={subListItem.name}
                      sx={{
                        minHeight: 30,
                        justifyContent: openDrawer ? "initial" : "center",
                      }}
                      onClick={() => handleCloseUserMenu(subListItem.redirect)}
                      className={`px-12 w-[100%]`}
                      disabled={subListItem?.name === "All Payment Links"}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: openDrawer ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {<subListItem.icon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={subListItem?.name}
                        sx={{ opacity: openDrawer ? 1 : 0, fontSize: "0.9rem" }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          ) : (
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: openDrawer ? "initial" : "center",
              }}
              onClick={() => handleCloseUserMenu(menuItem.redirect)}
              className="px-6 w-[100%]"
              disabled={menuItem.disabled}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: openDrawer ? 1 : "auto",
                  justifyContent: "center",
                }}
                className="bg-gradient-to-r from-[#7254D0] to-[#FF94D5] rounded-full p-2"
              >
                {<menuItem.icon style={{ color: "white" }} />}
              </ListItemIcon>
              <ListItemText primary={menuItem.name} sx={{ opacity: openDrawer ? 1 : 0 }} />
            </ListItemButton>
          )}
        </ListItem>
      ))}
    </>
  );
};
