export const GET_PAYMENT_INTENT_LOADING = "GET_PAYMENT_INTENT_LOADING";
export const GET_PAYMENT_INTENT_LOADING_COMPLETE = "GET_PAYMENT_INTENT_LOADING_COMPLETE";
export const GET_PAYMENT_INTENT_SUCCESS = "GET_PAYMENT_INTENT_SUCCESS";
export const GET_PAYMENT_INTENT_FAILURE = "GET_PAYMENT_INTENT_FAILURE";
export const RESET_GET_PAYMENT_INTENT = "RESET_GET_PAYMENT_INTENT";

export const GET_REQUEST_LOGS_LOADING = "GET_REQUEST_LOGS_LOADING";
export const GET_REQUEST_LOGS_LOADING_COMPLETE = "GET_REQUEST_LOGS_LOADING_COMPLETE";
export const GET_REQUEST_LOGS_SUCCESS = "GET_REQUEST_LOGS_SUCCESS";
export const GET_REQUEST_LOGS_FAILURE = "GET_REQUEST_LOGS_FAILURE";
export const RESET_GET_REQUEST_LOGS = "RESET_GET_REQUEST_LOGS";

export const GET_KNOW_IPS_LOADING = "GET_KNOW_IPS_LOADING";
export const GET_KNOW_IPS_LOADING_COMPLETE = "GET_KNOW_IPS_LOADING_COMPLETE";
export const GET_KNOW_IPS_SUCCESS = "GET_KNOW_IPS_SUCCESS";
export const GET_KNOW_IPS_FAILURE = "GET_KNOW_IPS_FAILURE";
export const RESET_GET_KNOW_IPS = "RESET_GET_KNOW_IPS";

export const CREATE_KNOW_IPS_LOADING = "CREATE_KNOW_IPS_LOADING";
export const CREATE_KNOW_IPS_LOADING_COMPLETE = "CREATE_KNOW_IPS_LOADING_COMPLETE";
export const CREATE_KNOW_IPS_SUCCESS = "CREATE_KNOW_IPS_SUCCESS";
export const CREATE_KNOW_IPS_FAILURE = "CREATE_KNOW_IPS_FAILURE";
export const RESET_CREATE_KNOW_IPS_STATUS = "RESET_CREATE_KNOW_IPS_STATUS";

export const UPDATE_KNOWN_IPS_LOADING = "UPDATE_KNOWN_IPS_LOADING";
export const UPDATE_KNOWN_IPS_LOADING_COMPLETE = "UPDATE_KNOWN_IPS_LOADING_COMPLETE";
export const UPDATE_KNOWN_IPS_SUCCESS = "UPDATE_KNOWN_IPS_SUCCESS";
export const UPDATE_KNOWN_IPS_FAILURE = "UPDATE_KNOWN_IPS_FAILURE";
export const RESET_UPDATE_KNOWN_IPS_STATUS = "RESET_UPDATE_KNOWN_IPS_STATUS";

export const GET_REF_REQUEST_LOGS_LOADING = "GET_REF_REQUEST_LOGS_LOADING";
export const GET_REF_REQUEST_LOGS_LOADING_COMPLETE = "GET_REF_REQUEST_LOGS_LOADING_COMPLETE";
export const GET_REF_REQUEST_LOGS_SUCCESS = "GET_REF_REQUEST_LOGS_SUCCESS";
export const GET_REF_REQUEST_LOGS_FAILURE = "GET_REF_REQUEST_LOGS_FAILURE";
export const RESET_GET_REF_REQUEST_LOGS = "RESET_GET_REF_REQUEST_LOGS";

export const GET_FORWARD_FOR_REQUEST_LOGS_LOADING = "GET_FORWARD_FOR_REQUEST_LOGS_LOADING";
export const GET_FORWARD_FOR_REQUEST_LOGS_LOADING_COMPLETE = "GET_FORWARD_FOR_REQUEST_LOGS_LOADING_COMPLETE";
export const GET_FORWARD_FOR_REQUEST_LOGS_SUCCESS = "GET_FORWARD_FOR_REQUEST_LOGS_SUCCESS";
export const GET_FORWARD_FOR_REQUEST_LOGS_FAILURE = "GET_FORWARD_FOR_REQUEST_LOGS_FAILURE";
export const RESET_GET_FORWARD_FOR_REQUEST_LOGS = "RESET_GET_FORWARD_FOR_REQUEST_LOGS";

export function getPaymentIntentLoading() {
  return {
    type: GET_PAYMENT_INTENT_LOADING,
  };
}

export function getPaymentIntentLoadingComplete() {
  return {
    type: GET_PAYMENT_INTENT_LOADING_COMPLETE,
  };
}

export function getPaymentIntentSuccess(response) {
  return {
    type: GET_PAYMENT_INTENT_SUCCESS,
    payload: response,
  };
}

export function getPaymentIntentFailure(response) {
  return {
    type: GET_PAYMENT_INTENT_FAILURE,
    payload: response,
  };
}

export const resetGetPaymentIntent = () => {
  return {
    type: RESET_GET_PAYMENT_INTENT,
  };
};

export function getRequestLogsLoading() {
  return {
    type: GET_REQUEST_LOGS_LOADING,
  };
}

export function getRequestLogsLoadingComplete() {
  return {
    type: GET_REQUEST_LOGS_LOADING_COMPLETE,
  };
}

export function getRequestLogsSuccess(response) {
  return {
    type: GET_REQUEST_LOGS_SUCCESS,
    payload: response,
  };
}

export function getRequestLogsFailure(response) {
  return {
    type: GET_REQUEST_LOGS_FAILURE,
    payload: response,
  };
}

export const resetGetRequestLogs = () => {
  return {
    type: RESET_GET_REQUEST_LOGS,
  };
};

export function getKnowIpsLoading() {
  return {
    type: GET_KNOW_IPS_LOADING,
  };
}

export function getKnowIpsLoadingComplete() {
  return {
    type: GET_KNOW_IPS_LOADING_COMPLETE,
  };
}

export function getKnowIpsSuccess(response) {
  return {
    type: GET_KNOW_IPS_SUCCESS,
    payload: response,
  };
}

export function getKnowIpsFailure(response) {
  return {
    type: GET_KNOW_IPS_FAILURE,
    payload: response,
  };
}

export const resetGetKnowIps = () => {
  return {
    type: RESET_GET_KNOW_IPS,
  };
};

export function createKnowIpsLoading() {
  return {
    type: CREATE_KNOW_IPS_LOADING,
  };
}

export function createKnowIpsLoadingComplete() {
  return {
    type: CREATE_KNOW_IPS_LOADING_COMPLETE,
  };
}
export function createKnowIpsFailure(response) {
  return {
    type: CREATE_KNOW_IPS_FAILURE,
    payload: response,
  };
}

export function createKnowIpsSuccess(response) {
  return {
    type: CREATE_KNOW_IPS_SUCCESS,
    payload: response,
  };
}

export function resetCreateKnowIpsStatus() {
  return {
    type: RESET_CREATE_KNOW_IPS_STATUS,
  };
}

export function updateKnownIpsLoading() {
  return {
    type: UPDATE_KNOWN_IPS_LOADING,
  };
}

export function updateKnownIpsLoadingComplete() {
  return {
    type: UPDATE_KNOWN_IPS_LOADING_COMPLETE,
  };
}
export function updateKnownIpsFailure() {
  return {
    type: UPDATE_KNOWN_IPS_FAILURE,
  };
}

export function updateKnownIpsSuccess(response) {
  return {
    type: UPDATE_KNOWN_IPS_SUCCESS,
    payload: response,
  };
}

export function resetUpdateKnownIpsStatus() {
  return {
    type: RESET_UPDATE_KNOWN_IPS_STATUS,
  };
}

export function getRefRequestLogsLoading() {
  return {
    type: GET_REF_REQUEST_LOGS_LOADING,
  };
}

export function getRefRequestLogsLoadingComplete() {
  return {
    type: GET_REF_REQUEST_LOGS_LOADING_COMPLETE,
  };
}

export function getRefRequestLogsSuccess(response) {
  return {
    type: GET_REF_REQUEST_LOGS_SUCCESS,
    payload: response,
  };
}

export function getRefRequestLogsFailure(response) {
  return {
    type: GET_REF_REQUEST_LOGS_FAILURE,
    payload: response,
  };
}

export const resetGetRefRequestLogs = () => {
  return {
    type: RESET_GET_REF_REQUEST_LOGS,
  };
};

export function getForwardForRequestLogsLoading() {
  return {
    type: GET_FORWARD_FOR_REQUEST_LOGS_LOADING,
  };
}

export function getForwardForRequestLogsLoadingComplete() {
  return {
    type: GET_FORWARD_FOR_REQUEST_LOGS_LOADING_COMPLETE,
  };
}

export function getForwardForRequestLogsSuccess(response) {
  return {
    type: GET_FORWARD_FOR_REQUEST_LOGS_SUCCESS,
    payload: response,
  };
}

export function getForwardForRequestLogsFailure(response) {
  return {
    type: GET_FORWARD_FOR_REQUEST_LOGS_FAILURE,
    payload: response,
  };
}

export const resetGetForwardForRequestLogs = () => {
  return {
    type: RESET_GET_FORWARD_FOR_REQUEST_LOGS,
  };
};
