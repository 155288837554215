import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRefRequestLogs } from "../../redux/services/paymentIntentServices";

const RefRequestLogs = ({ getRefRequestLogs, token, getRefRequestLogsResponse }) => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const ref = state?.ref;

  useEffect(() => {
    if (token) {
      getRefRequestLogs(ref, token);
    }
  }, [token, getRefRequestLogs, ref]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleForwardForClick = (forwardFor) => {
    const splitForwardArray = forwardFor.split(",");
    navigate("/request-logs/forward-for", { state: { forwardFor: splitForwardArray[0] } });
  };
  return (
    <>
      <div className="mt-10 flex justify-center font-semibold text-2xl">Request Log Filter By Ref</div>
      <TableContainer component={Card} className="mt-6 min-w-[100px] max-w-[100%]">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="font-bold">Timestamp</TableCell>
              <TableCell className="font-bold">IP</TableCell>
              <TableCell className="font-bold">Path</TableCell>
              <TableCell className="font-bold">Referer</TableCell>
              <TableCell className="font-bold">User Agent</TableCell>
              <TableCell className="font-bold">Ref</TableCell>
              <TableCell className="font-bold">ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getRefRequestLogsResponse && getRefRequestLogsResponse?.length > 0 ? (
              getRefRequestLogsResponse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{moment(request.received_timestamp).format("MM/DD/YYYY HH:mm:ss")}</TableCell>
                  <TableCell
                    className="text-blue-500 hover:text-blue-600 hover:cursor-pointer hover:underline"
                    onClick={() => handleForwardForClick(request.xforwarded_for)}
                  >
                    {request.xforwarded_for}
                  </TableCell>
                  <TableCell>{request.servlet_path}</TableCell>
                  <TableCell>{request.referer}</TableCell>
                  <TableCell>
                    {request.user_agent ? (
                      <Tooltip title={request.user_agent}>
                        <span>{request.user_agent.slice(0, 5)}...</span>
                      </Tooltip>
                    ) : null}
                  </TableCell>
                  {request.ref ? (
                    <TableCell>
                      <Tooltip title={request.ref ?? ""}>
                        <span>{request.ref?.slice(0, 5)}...</span>
                      </Tooltip>
                    </TableCell>
                  ) : null}
                  {request.id ? (
                    <TableCell>
                      <Tooltip title={request.id ?? ""}>
                        <span>{request.id?.slice(0, 5)}...</span>
                      </Tooltip>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>No Ref request logs available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 25, 50]}
          component="div"
          count={getRefRequestLogsResponse?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    getRefRequestLogsResponse: state.paymentIntent.getRefRequestLogsResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRefRequestLogs: (ref, token) => {
      dispatch(getRefRequestLogs(ref, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefRequestLogs);
