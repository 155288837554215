const REGISTER_USER = "REGISTER_USER";
const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
const REDIRECT_TO_Register = "REDIRECT_TO_Register";

const REDIRECT_TO_UPDATE_PWD = "REDIRECT_TO_UPDATE_PWD";

const DB_ERROR = "DB_ERROR";
const SET_CURRENT_USER = "SET_CURRENT_USER";
const SETUP_PROFILE_STEP1 = "SETUP_PROFILE_STEP1";
const ERROR = "ERROR";
const API_ERROR = "API_ERROR";
const REDIRECT_TO_RESET_PWD = "REDIRECT_TO_RESET_PWD";
const REDIRECT_TO_KYC_STEP_1 = "REDIRECT_TO_KYC_STEP_1";
const REDIRECT_TO_KYC_STEP_2 = "REDIRECT_TO_KYC_STEP_2";
const REDIRECT_TO_KYC_STEP_3 = "REDIRECT_TO_KYC_STEP_3";

export const SET_TOKEN = "SET_TOKEN";

export function redirectTologIn(response) {
  return {
    type: REDIRECT_TO_LOGIN,
    payload: response,
    useverificationstatus: response.data,
  };
}

export function redirectToRegister(status) {
  return {
    type: REDIRECT_TO_Register,
    payload: status,
    useverificationstatus: status,
  };
}

export function logIn() {}

export function loginSuccess(token) {
  return {
    type: REDIRECT_TO_UPDATE_PWD,
    payload: token,
    msg: "Kindly reset your password!",
    //useverificationstatus: status
  };
}

export function register(users) {
  return {
    type: REGISTER_USER,
    payload: users,
  };
}

export function LogOut() {}
export function dbError(error) {
  return {
    type: DB_ERROR,
    payload: error,
  };
}
export function responseFail(error) {
  if (error === false) {
    // console.log('11111');
    var message = "401 Unauthorize!!";
  } else {
    message = error;
  }
  return {
    type: API_ERROR,
    payload: error,
    message: message,
  };
}

export function setCurrentUser(userdata) {
  return {
    type: SET_CURRENT_USER,
    payload: userdata,
  };
}

export function loginFail(token) {
  return {
    type: REDIRECT_TO_UPDATE_PWD,
    payload: token,
    msg: "Error while connecting to API!!!",
    //useverificationstatus: status
  };
}
export function updatepwdFail(status, msg) {
  // console.log(msg);
  return {
    type: REDIRECT_TO_UPDATE_PWD,
    payload: status,
    msg: msg,
    //useverificationstatus: status
  };
}
export function updatepwdSuccess(status, msg) {
  //console.log("hereinerror")
  return {
    type: REDIRECT_TO_UPDATE_PWD,
    payload: status,
    msg: msg,
    //useverificationstatus: status
  };
}

export function setupProfilestep1ActionFail(status) {
  // console.log("in action fail");
  return {
    type: ERROR,
    payload: status,
    msg: "Profile not updated!!",
    //useverificationstatus: status
  };
}
export function setupProfilestep1ActionSuccess(status) {
  // console.log("in action success");
  return {
    type: SETUP_PROFILE_STEP1,
    payload: status,
    msg: "Kindly enter OTP!!",
    //useverificationstatus: status
  };
}
export function LoginWithTempDetailsAction(response) {
  return {
    type: REDIRECT_TO_RESET_PWD,
    payload: response,
  };
}

export function resetPasswordSuccess(response) {
  return {
    type: REDIRECT_TO_KYC_STEP_1,
    payload: response,
  };
}
export function logInSuccess(response) {
  return {
    type: REDIRECT_TO_KYC_STEP_1,
    payload: response,
  };
}

export function setupProfilestep1Success(response) {
  return {
    type: REDIRECT_TO_KYC_STEP_2,
    payload: response,
  };
}

export function setupProfilestep2Success(response) {
  return {
    type: REDIRECT_TO_KYC_STEP_3,
    payload: response,
  };
}


export function setToken(response) {
  return {
    type: SET_TOKEN,
    payload: response,
  };
} 