import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { InputController } from "../ReusableComponents/InputBox";
import { SelectBlockKnownIp } from "../ReusableComponents/SelcetBlockKnownIp";

const KnownIpsInformation = ({ control, errors, handleSubmit, onSubmit }) => {
  const { state } = useLocation();
  const editMode = state?.editMode;
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/know-ips");
  };
  return (
    <div className="flex flex-col gap-4 justify-center">
      {!editMode && (
        <InputController
          control={control}
          errors={errors.ip}
          name="ip"
          label={"IP"}
          type={"text"}
          width={"w-full"}
          size="small"
        />
      )}
      <SelectBlockKnownIp control={control} errors={errors.blocked} name="blocked" label={"Block Known Ip"} />
      <InputController
        control={control}
        errors={errors.owner_name}
        name="owner_name"
        label={"Owner Name"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <InputController
        control={control}
        errors={errors.user_type}
        name="user_type"
        label={"User Type"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <InputController
        control={control}
        errors={errors.location}
        name="location"
        label={"Location"}
        type={"text"}
        width={"w-full"}
        size="small"
      />

      <div className="flex items-center justify-center">
        <Box className="flex flex-row justify-center mx-auto gap-2 w-[180px] mt-3">
          <Button
            onClick={handleCancel}
            variant="contained"
            className="mx-auto w-[200px] text-lg text-white mt-4 normal-case"
          >
            <span>Cancel</span>
          </Button>
          <Button
            type="button"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            className="mx-auto w-[200px] text-lg text-white mt-4 normal-case"
          >
            <span>Submit</span>
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default KnownIpsInformation;
