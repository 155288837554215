import { useAuth0 } from "@auth0/auth0-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutRounded from "@mui/icons-material/LogoutRounded";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Menu, MenuItem, useMediaQuery } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DisplayDrawerMenu } from "./ReusableComponents/DisplayDrawerMenu";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const settings = [
  {
    id: "logout",
    name: "Logout",
    icon: LogoutRounded,
    redirect: "/logout",
    isKycCheckRequire: false,
  },
];

const usePrevious = (value) => {
  const ref = React.useRef(null);
  React.useEffect(() => void (ref.current = value), [value]);
  return ref.current;
};

const SideDrawer = (props) => {
  const { userPortfolioSummary, userdata, children } = props;
  const KycStatus = userdata?.kyc_status;
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [nextPage, setNextPage] = React.useState(null);
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const { logout, user } = useAuth0();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (nextPage !== null) {
      let temp = nextPage;
      setNextPage(null);
      navigate(temp);
    }
  }, [nextPage, navigate]);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  React.useEffect(() => {
    console.log(openDrawer);
  }, [openDrawer]);

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
  };

  const handleCloseUserMenu = (action) => {
    setAnchorElUser(null);
    if (action === "/logout") {
      logout();
    } else if (action !== "none") {
      setNextPage(action);
    }
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const isBigScreen = useMediaQuery("(min-width: 1140px)", false);
  const prevBigScreen = usePrevious(isBigScreen);
  React.useEffect(() => {
    if (isBigScreen !== prevBigScreen && isBigScreen !== openDrawer) {
      setOpenDrawer((prev) => !prev);
    }
  }, [isBigScreen, prevBigScreen, openDrawer]);
  const formatMoney = (num) => {
    return `$${(Math.round(num * 100) / 100).toFixed(2)}`;
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={openDrawer} style={{ background: "#ffffff" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={`${openDrawer ? "hidden" : "block  mr-4 text-black"}`}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img width={170} height={40} src={"https://logos.payspaze.com/PaySpaze_Logo_horizontal.svg"} alt="logo" />
            </Typography>
            {user && (
              <Avatar alt="user.name" src={user.picture} onClick={handleOpenUserMenu} className="hover:cursor-pointer">
                {user.name[0]}
              </Avatar>
            )}
            <Menu
              sx={{
                color: "#000",
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => handleCloseUserMenu("none")}
              className="mt-10 mr-0"
            >
              {userPortfolioSummary && (
                <MenuItem key={"Balance"}>
                  <img
                    style={{
                      width: "57px",
                      height: "53px",
                      float: "left",
                      alt: "logo",
                    }}
                    src="/images/balance.png"
                    alt="Balance"
                  ></img>
                  <Typography style={{ color: "black", whiteSpace: "pre-line" }}>
                    {"   Balance<br/>   USD ".split("<br/>").join("\n")}
                    {formatMoney(userPortfolioSummary.balance.amount)}
                  </Typography>
                </MenuItem>
              )}
              <MenuItem className="hover:cursor-auto">
                <ListItemIcon className="w-max mr-3">
                  {user && (
                    <Avatar alt="user.name" src={user.picture}>
                      {user.name[0]}
                    </Avatar>
                  )}
                </ListItemIcon>
                <Typography component={"div"} textAlign="center" className="text-slate-500 text-sm font-normal">
                  <div>{user?.email}</div>
                </Typography>
              </MenuItem>
              <Divider variant="fullWidth" sx={{ mt: 1, borderBottomWidth: "1.5px", borderColor: "grey" }}></Divider>
              {settings.map((setting) => (
                <MenuItem
                  key={setting.name}
                  onClick={() => handleCloseUserMenu(setting.redirect)}
                  disabled={setting.isKycCheckRequire && KycStatus !== "verified"}
                >
                  <ListItemIcon className="w-max">
                    <setting.icon
                      style={{ color: "white" }}
                      className="w-9 h-9 bg-gradient-to-r from-[#7254D0] to-[#FF94D5] rounded-full p-2 mr-3"
                    />
                  </ListItemIcon>
                  <Typography textAlign="center" style={{ color: "black" }}>
                    {setting.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={openDrawer}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <DisplayDrawerMenu openDrawer={openDrawer} handleCloseUserMenu={handleCloseUserMenu} />
          </List>
          <Divider />
          <ListItem disablePadding sx={{ display: "block" }} className="p-0">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: openDrawer ? "initial" : "center",
              }}
              className="px-6 w-[100%]"
              onClick={() => handleCloseUserMenu("/logout")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: openDrawer ? 1 : "auto",
                  justifyContent: "center",
                }}
                className="bg-gradient-to-r from-[#7254D0] to-[#FF94D5] rounded-full p-2"
              >
                <LogoutRounded style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary={"Logout"} sx={{ opacity: openDrawer ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }} className="px-4">
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    userPortfolioSummary: state.home.userPortfolioSummary,
    userdata: state.auth.userdata,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {};
};
export default connect(mapStatetoProps, mapDispatchtoProps)(SideDrawer);
