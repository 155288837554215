const REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY = "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY";
const RESET_AND_REDIRECT_HOME = "RESET_AND_REDIRECT_HOME";
const REDIRECT_KYC_START_SUCCESS = "REDIRECT_KYC_START_FAIL";
const REDIRECT_KYC_START_FAIL = "REDIRECT_KYC_START_FAIL";

export const SEND_NOTIFS_SUCCESS = "SEND_NOTIFS_SUCCESS";
export const SEND_NOTIFS_FAIL = "SEND_NOTIFS_FAIL";


export function getPortfolioSummarySuccess(response) {
  return {
    type: REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY,
    payload: response,
  }
}


export function kycStartSuccess(response) {
  return {
    type: REDIRECT_KYC_START_SUCCESS,
    payload: response
  }
}

export function kycStartFail(response) {
  return {
    type: REDIRECT_KYC_START_FAIL,
    payload: response
  }
}

export function resetHomeState() {
  return {
    type: RESET_AND_REDIRECT_HOME,
  }
}

export function sendNotificationsSuccess(response) {
  return {
    type: SEND_NOTIFS_SUCCESS,
    payload: response
  }
}

export function sendNotificationsFail(response) {
  return {
    type: SEND_NOTIFS_FAIL,
    payload: response
  }
}