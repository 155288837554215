import { SelectNotificationOptions } from "./ReusableComponents/SelectNotificationOptions";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { connect } from "react-redux";
import { sendNotifications } from "../redux/services/homeServices";

const Notifications = ({ callSendNotifications, sendNotificationsStatus, token }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object().shape({
    occcasion: Yup.string().required("Unit is required"),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setOpen(false);
    const payload = {
      message_subject: data.occcasion,
      message_severity: "general",
    };
    console.log(payload);
    callSendNotifications(payload, token);
  };
  return (
    <Box>
      <Box sx={{ mt: 16, display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
        <SelectNotificationOptions control={control} errors={errors.occcasion} name="occcasion" label={"Occasion"} />
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Send
        </Button>
      </Box>
      {sendNotificationsStatus === 200 && <Box sx={{ color: "green" }}>Sent Successfully </Box>}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{
          style: {
            width: "400px",
            height: "150px",
          },
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>Are you sure you want to notify all the attendees?</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button onClick={handleSubmit(onSubmit)} variant="contained" className="mb-2 text-white">
            <span>Notify</span>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    sendNotificationsStatus: state.home.sendNotificationsStatus,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callSendNotifications: function (data, token) {
      dispatch(sendNotifications(data, token));
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Notifications);
