import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const unitOptions = [
  {
    name: "Aarti Starting",
    abbreviation: "aarti",
  },
  {
    name: "Maha Arti Starting",
    abbreviation: "mahaaarti",
  },
];

export const SelectNotificationOptions = ({ control, errors, name, label, width }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <TextField
          onChange={onChange}
          value={value || ""}
          label={label}
          variant="outlined"
          className={`${width} text-left`}
          select
          error={!!errors}
          helperText={errors?.message}
          InputLabelProps={{ required: true }}
          size="small"
          sx={{ width: "200px" }}
        >
          {unitOptions.map((unit, index) => (
            <MenuItem value={unit.abbreviation} key={index}>
              {unit.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
