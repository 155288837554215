import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const blockIpOptions = [
  {
    value: "true",
    label: "True",
  },
  {
    value: "false",
    label: "False",
  },
];

export const SelectBlockKnownIp = ({ control, errors, name, label, width }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <TextField
          onChange={onChange}
          value={value || ""}
          label={label}
          variant="outlined"
          className={`${width} text-left`}
          select
          error={!!errors}
          helperText={errors?.message}
          InputLabelProps={{ required: true }}
          size="small"
        >
          {blockIpOptions.map((blockIp, index) => (
            <MenuItem value={blockIp.value} key={index}>
              {blockIp.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
