import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRequestLogs } from "../../redux/services/paymentIntentServices";
const RequestLogs = ({ getRequestLogs, getRequestLogsStatus, getRequestLogsResponse, token }) => {
  const navigate = useNavigate();
  const [copySuccessRef, setCopySuccessRef] = useState(null);

  useEffect(() => {
    if (token) {
      getRequestLogs(token);
    }
  }, [token, getRequestLogs]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleForwardForClick = (forwardFor) => {
    const splitForwardArray = forwardFor.split(",");
    navigate("/request-logs/forward-for", { state: { forwardFor: splitForwardArray[0] } });
  };

  const handleRefClick = (ref) => {
    console.log(ref);
    navigate("/request-logs/ref", { state: { ref: ref } });
  };

  const handleCopyRefClick = (ref) => {
    const textarea = document.createElement("textarea");
    textarea.value = ref;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopySuccessRef(ref);

    setTimeout(() => {
      setCopySuccessRef(null);
    }, 1800);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, getRequestLogsResponse?.length - page * rowsPerPage);

  return (
    <>
      <div className="mt-10 flex justify-center font-semibold text-2xl">Request Logs</div>
      <TableContainer component={Card} className="mt-6 min-w-[100px] max-w-[100%]">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="22%" className="font-bold">
                Timestamp
              </TableCell>
              <TableCell width="12%" className="font-bold">
                IP
              </TableCell>
              <TableCell width="12%" className="font-bold">
                Path
              </TableCell>
              <TableCell width="12%" className="font-bold">
                Referer
              </TableCell>
              <TableCell width="12%" className="font-bold">
                User Agent
              </TableCell>
              <TableCell width="12%" className="font-bold">
                Ref
              </TableCell>
              <TableCell width="12%" className="font-bold">
                ID
              </TableCell>
              <TableCell width="6%" className="font-bold">
                Method
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getRequestLogsResponse && getRequestLogsResponse?.length > 0
              ? getRequestLogsResponse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{moment(request.received_timestamp).format("MM/DD/YYYY HH:mm:ss")}</TableCell>
                    <TableCell
                      className="text-blue-500 hover:text-blue-600 hover:cursor-pointer hover:underline"
                      onClick={() => handleForwardForClick(request.xforwarded_for)}
                    >
                      {request.xforwarded_for}
                    </TableCell>
                    <TableCell>{request.servlet_path}</TableCell>
                    <TableCell>
                      <Tooltip title={request.referer ?? ""}>
                        <span>
                          {request.referer
                            ? request.referer.length > 20
                              ? request.referer.slice(0, 20) + "..."
                              : request.referer
                            : ""}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {request.user_agent ? (
                        <Tooltip title={request.user_agent}>
                          <span>{request.user_agent.slice(0, 50)}...</span>
                        </Tooltip>
                      ) : null}
                    </TableCell>
                    {request.ref ? (
                      <TableCell>
                        <Tooltip title={request.ref ?? ""}>
                          <span
                            className="text-blue-500 hover:text-blue-600 hover:cursor-pointer hover:underline"
                            onClick={() => handleRefClick(request.ref)}
                          >
                            {request.ref ? request.ref.slice(0, 20) + "..." : ""}
                          </span>
                        </Tooltip>
                        <span
                          className="text-[20px] justify-end cursor-pointer"
                          onClick={() => handleCopyRefClick(request.ref)}
                        >
                          <ContentCopyIcon
                            sx={{ marginLeft: "15px" }}
                            className="hover:text-blue-500 text-[1rem] font-semibold"
                          />
                        </span>
                        {copySuccessRef === request.ref && (
                          <span className="text-blue-500 ml-2 font-semibold">Copied!</span>
                        )}
                      </TableCell>
                    ) : null}
                    {request.id ? (
                      <TableCell>
                        <Tooltip title={request.id ?? ""}>
                          <span>{request.id?.slice(0, 50)}...</span>
                        </Tooltip>
                      </TableCell>
                    ) : null}
                    <TableCell>{request.method}</TableCell>
                  </TableRow>
                ))
              : emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7}></TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 25, 100, 300]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={getRequestLogsResponse?.length || 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    getRequestLogsStatus: state.paymentIntent.getRequestLogsStatus,
    getRequestLogsResponse: state.paymentIntent.getRequestLogsResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRequestLogs: (token) => {
      dispatch(getRequestLogs(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestLogs);
