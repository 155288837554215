import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import authReducer from "./reducer/authReducer";
import homeReducer from "./reducer/homeReducer";
import merchantReducer from "./reducer/merchantReducer";
import paymentIntentReducer from "./reducer/paymentIntentReducer";
const thunkMiddleware = require("redux-thunk").default;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const mainReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  merchant: merchantReducer,
  paymentIntent: paymentIntentReducer,

});
const store = createStore(mainReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));
export default store;
