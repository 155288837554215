import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetGetMerchantAuthentication } from "../../redux/action/merchantAction";
import { getMerchantAuthentication, getMerchantProfile } from "../../redux/services/merchantServices";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const ManageMerchant = ({
  getMerchantProfile,
  getMerchantProfileStatus,
  getMerchantProfileResponse,
  getMerchantProfileLoading,
  getMerchantAuthentication,
  getMerchantAuthenticationStatus,
  getMerchantAuthenticationResponse,
  deleteMerchantAuthentication,
  resetGetMerchantAuthentication,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    getMerchantProfile();
  }, [getMerchantProfile, getMerchantAuthentication]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedMerchantData = [...getMerchantProfileResponse].sort((a, b) => {
    if (orderBy === "name") {
      return (order === "asc" ? 1 : -1) * a.name.localeCompare(b.name);
    }
    if (orderBy === "merchant_profile_id") {
      return (order === "asc" ? 1 : -1) * (a.merchant_profile_id - b.merchant_profile_id);
    }
    if (orderBy === "remote_merchant_profile_id") {
      return (order === "asc" ? 1 : -1) * (a.remote_merchant_profile_id - b.remote_merchant_profile_id);
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditMerchantAuthenticationButtonClick = (id) => {
    getMerchantAuthentication(id);
  };

  const handleEditProfileButtonClick = (data) => {
    navigate("/manage/merchant-profiles", { state: { editMode: true, merchantData: data } });
  };

  useEffect(() => {
    if (getMerchantAuthenticationStatus === 200) {
      navigate("/create-merchant", { state: { editMode: true } });
      resetGetMerchantAuthentication();
    }
  }, [getMerchantAuthenticationStatus, navigate, resetGetMerchantAuthentication]);

  return (
    <>
      <div className="flex flex-row-reverse justify-start items-center mb-8 mt-11 gap-3">
        <Button
          variant="contained"
          color="primary"
          className="normal-case ml-3"
          onClick={() => navigate("/create-merchant")}
          disabled
          startIcon={<AddCircleOutlineIcon />}
        >
          Create Merchant Authentication
        </Button>
        <Button
          variant="contained"
          className="normal-case"
          color="primary"
          onClick={() => navigate("/manage/merchant-profiles")}
          disabled
          startIcon={<AddCircleOutlineIcon />}
        >
          Create Merchant Profile
        </Button>
      </div>
      <TableContainer component={Card} className="mt-6">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Logo</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order : "asc"}
                  onClick={() => handleSort("name")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "merchant_profile_id"}
                  direction={orderBy === "merchant_profile_id" ? order : "asc"}
                  onClick={() => handleSort("merchant_profile_id")}
                >
                  Merchant Profile ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "remote_merchant_profile_id"}
                  direction={orderBy === "remote_merchant_profile_id" ? order : "asc"}
                  onClick={() => handleSort("remote_merchant_profile_id")}
                >
                  Remote Profile ID
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedMerchantData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((merchant) => (
              <TableRow key={merchant.id}>
                <TableCell>
                  <span className="w-9/12 text-muted flex justify-end items-end">
                    <img width={100} height={100} src={merchant.logo_path} alt={merchant.name} />
                  </span>
                </TableCell>
                <TableCell>{merchant.name}</TableCell>

                <TableCell>{merchant.merchant_profile_id}</TableCell>
                <TableCell>{merchant.remote_merchant_profile_id}</TableCell>
                <TableCell>
                  <div className="flex gap-3 w-full">
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className="normal-case w-[10rem]"
                      onClick={() => handleEditMerchantAuthenticationButtonClick(merchant.remote_merchant_profile_id)}
                      startIcon={<EditIcon />}
                    >
                      Edit Authentication
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => handleEditProfileButtonClick(merchant)}
                      className="normal-case w-[7rem]"
                      startIcon={<EditIcon />}
                    >
                      Edit Profile
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 25]}
          component="div"
          count={sortedMerchantData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    getMerchantProfileStatus: state.merchant.getMerchantProfileStatus,
    getMerchantProfileLoading: state.merchant.getMerchantProfileLoading,
    getMerchantProfileResponse: state.merchant.getMerchantProfileResponse,
    getMerchantAuthenticationStatus: state.merchant.getMerchantAuthenticationStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchantProfile: () => {
      dispatch(getMerchantProfile());
    },
    getMerchantAuthentication: (id) => {
      dispatch(getMerchantAuthentication(id));
    },
    resetGetMerchantAuthentication: () => {
      dispatch(resetGetMerchantAuthentication());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageMerchant);
