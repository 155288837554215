import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Controller } from "react-hook-form";

export const DateController = ({ control, errors, name, label, width, maxDate, minDate }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, ...props }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              name="dateOfBirth"
              className={width}
              label={label}
              maxDate={maxDate}
              minDate={minDate}
              value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
              onChange={(date) => {
                field.onChange(moment(date).format("YYYY-MM-DD"));
              }}
              inputVariant="outlined"
              autoOk
              format="DD/MM/YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  name={name}
                  error={!!errors}
                  helperText={errors?.message}
                  variant="outlined"
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
