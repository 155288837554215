import {
  GET_PAYMENT_INTENT_FAILURE,
  GET_PAYMENT_INTENT_LOADING,
  GET_PAYMENT_INTENT_LOADING_COMPLETE,
  GET_PAYMENT_INTENT_SUCCESS,
  RESET_GET_PAYMENT_INTENT,
  GET_REQUEST_LOGS_FAILURE,
  GET_REQUEST_LOGS_LOADING,
  GET_REQUEST_LOGS_LOADING_COMPLETE,
  GET_REQUEST_LOGS_SUCCESS,
  RESET_GET_REQUEST_LOGS,
  GET_KNOW_IPS_FAILURE,
  GET_KNOW_IPS_LOADING,
  GET_KNOW_IPS_LOADING_COMPLETE,
  GET_KNOW_IPS_SUCCESS,
  RESET_GET_KNOW_IPS,
  CREATE_KNOW_IPS_LOADING,
  CREATE_KNOW_IPS_LOADING_COMPLETE,
  CREATE_KNOW_IPS_SUCCESS,
  CREATE_KNOW_IPS_FAILURE,
  RESET_CREATE_KNOW_IPS_STATUS,
  UPDATE_KNOWN_IPS_LOADING_COMPLETE,
  UPDATE_KNOWN_IPS_FAILURE,
  UPDATE_KNOWN_IPS_LOADING,
  UPDATE_KNOWN_IPS_SUCCESS,
  RESET_UPDATE_KNOWN_IPS_STATUS,
  GET_REF_REQUEST_LOGS_FAILURE,
  GET_REF_REQUEST_LOGS_LOADING,
  GET_REF_REQUEST_LOGS_LOADING_COMPLETE,
  GET_REF_REQUEST_LOGS_SUCCESS,
  RESET_GET_REF_REQUEST_LOGS,
  GET_FORWARD_FOR_REQUEST_LOGS_FAILURE,
  GET_FORWARD_FOR_REQUEST_LOGS_LOADING,
  GET_FORWARD_FOR_REQUEST_LOGS_LOADING_COMPLETE,
  GET_FORWARD_FOR_REQUEST_LOGS_SUCCESS,
  RESET_GET_FORWARD_FOR_REQUEST_LOGS,
} from "../action/paymentIntentAction";

export const initialState = {
  getPaymentIntentLoading: false,
  getPaymentIntentResponse: [],
  getPaymentIntentStatus: null,

  getRequestLogsLoading: false,
  getRequestLogsResponse: {},
  getRequestLogsStatus: null,

  getKnowIpsLoading: false,
  getKnowIpsResponse: [],
  getKnowIpsStatus: null,

  createKnowIpsStatus: null,
  createKnowIpsResponse: {},
  createKnowIpsLoading: false,

  updateKnownIpsStatus: null,
  updateKnownIpsResponse: {},
  updateKnownIpsLoading: false,

  getRefRequestLogsLoading: false,
  getRefRequestLogsResponse: [],
  getRefRequestLogsStatus: null,

  getForwardForRequestLogsLoading: false,
  getForwardForRequestLogsResponse: [],
  getForwardForRequestLogsStatus: null,
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_INTENT_LOADING:
      return {
        ...state,
        getPaymentIntentLoading: true,
      };
    case GET_PAYMENT_INTENT_LOADING_COMPLETE:
      return {
        ...state,
        getPaymentIntentLoading: false,
      };
    case GET_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        getPaymentIntentResponse: action.payload.data,
        getPaymentIntentStatus: action.payload.status,
      };
    case GET_PAYMENT_INTENT_FAILURE:
      return {
        ...state,
        getPaymentIntentResponse: action.payload.data,
        getPaymentIntentStatus: action.payload.status,
      };
    case RESET_GET_PAYMENT_INTENT:
      return {
        ...state,
        getPaymentIntentResponse: [],
        getPaymentIntentStatus: null,
      };

    case GET_REQUEST_LOGS_LOADING:
      return {
        ...state,
        getRequestLogsLoading: true,
      };
    case GET_REQUEST_LOGS_LOADING_COMPLETE:
      return {
        ...state,
        getRequestLogsLoading: false,
      };
    case GET_REQUEST_LOGS_SUCCESS:
      return {
        ...state,
        getRequestLogsResponse: action.payload.data,
        getRequestLogsStatus: action.payload.status,
      };
    case GET_REQUEST_LOGS_FAILURE:
      return {
        ...state,
        getRequestLogsResponse: action.payload.data,
        getRequestLogsStatus: action.payload.status,
      };
    case RESET_GET_REQUEST_LOGS:
      return {
        ...state,
        getRequestLogsResponse: {},
        getRequestLogsStatus: null,
      };

    case GET_KNOW_IPS_LOADING:
      return {
        ...state,
        getKnowIpsLoading: true,
      };
    case GET_KNOW_IPS_LOADING_COMPLETE:
      return {
        ...state,
        getKnowIpsLoading: false,
      };
    case GET_KNOW_IPS_SUCCESS:
      return {
        ...state,
        getKnowIpsResponse: action.payload.data,
        getKnowIpsStatus: action.payload.status,
      };
    case GET_KNOW_IPS_FAILURE:
      return {
        ...state,
        getKnowIpsResponse: action.payload.data,
        getKnowIpsStatus: action.payload.status,
      };
    case RESET_GET_KNOW_IPS:
      return {
        ...state,
        getKnowIpsResponse: [],
        getKnowIpsStatus: null,
      };

    case CREATE_KNOW_IPS_LOADING:
      return {
        ...state,
        createKnowIpsLoading: true,
      };
    case CREATE_KNOW_IPS_LOADING_COMPLETE:
      return {
        ...state,
        createKnowIpsLoading: false,
      };
    case CREATE_KNOW_IPS_SUCCESS:
      return {
        ...state,
        createKnowIpsResponse: action.payload.data,
        createKnowIpsStatus: action.payload.status,
      };
    case CREATE_KNOW_IPS_FAILURE:
      return {
        ...state,
        createKnowIpsResponse: action?.payload?.data,
        createKnowIpsStatus: action.payload.status,
      };
    case RESET_CREATE_KNOW_IPS_STATUS:
      return {
        ...state,
        createKnowIpsStatus: null,
      };

    case UPDATE_KNOWN_IPS_LOADING:
      return {
        ...state,
        updateKnownIpsLoading: true,
      };
    case UPDATE_KNOWN_IPS_LOADING_COMPLETE:
      return {
        ...state,
        updateKnownIpsLoading: false,
      };
    case UPDATE_KNOWN_IPS_SUCCESS:
      return {
        ...state,
        updateKnownIpsResponse: action.payload.data,
        updateKnownIpsStatus: action.payload.status,
      };
    case UPDATE_KNOWN_IPS_FAILURE:
      return {
        ...state,
        updateKnownIpsResponse: action?.payload?.data,
        updateKnownIpsStatus: action.payload.status,
      };
    case RESET_UPDATE_KNOWN_IPS_STATUS:
      return {
        ...state,
        updateKnownIpsStatus: null,
      };

    case GET_REF_REQUEST_LOGS_LOADING:
      return {
        ...state,
        getRefRequestLogsLoading: true,
      };
    case GET_REF_REQUEST_LOGS_LOADING_COMPLETE:
      return {
        ...state,
        getRefRequestLogsLoading: false,
      };
    case GET_REF_REQUEST_LOGS_SUCCESS:
      return {
        ...state,
        getRefRequestLogsResponse: action.payload.data,
        getRefRequestLogsStatus: action.payload.status,
      };
    case GET_REF_REQUEST_LOGS_FAILURE:
      return {
        ...state,
        getRefRequestLogsResponse: action.payload.data,
        getRefRequestLogsStatus: action.payload.status,
      };
    case RESET_GET_REF_REQUEST_LOGS:
      return {
        ...state,
        getRefRequestLogsResponse: [],
        getRefRequestLogsStatus: null,
      };

    case GET_FORWARD_FOR_REQUEST_LOGS_LOADING:
      return {
        ...state,
        getForwardForRequestLogsLoading: true,
      };
    case GET_FORWARD_FOR_REQUEST_LOGS_LOADING_COMPLETE:
      return {
        ...state,
        getForwardForRequestLogsLoading: false,
      };
    case GET_FORWARD_FOR_REQUEST_LOGS_SUCCESS:
      return {
        ...state,
        getForwardForRequestLogsResponse: action.payload.data,
        getForwardForRequestLogsStatus: action.payload.status,
      };
    case GET_FORWARD_FOR_REQUEST_LOGS_FAILURE:
      return {
        ...state,
        getForwardForRequestLogsResponse: action.payload.data,
        getForwardForRequestLogsStatus: action.payload.status,
      };
    case RESET_GET_FORWARD_FOR_REQUEST_LOGS:
      return {
        ...state,
        getForwardForRequestLogsResponse: [],
        getForwardForRequestLogsStatus: null,
      };
    default:
      return state;
  }
}
